.footer-content {
  @media screen and (max-width: 992px) {
    display: flex;
    justify-content: center;
    padding-top: 1em;
  }
}

.title {
  font-family: 'Manrope';
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
}

.textual {
  font-family: 'Manrope';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 1em;
}

.footer-text {
  @media screen and (max-width:767px) {
    margin-left: 0;
    margin-top: 30px !important;
  }
}

.link-color {
  color: rgba(255, 255, 255, 0.75);
}

.link-space {
  margin-left: 15px;
}

.link-mail {
  margin-left: 30px;
  @media screen and (max-width: 992px) {
    margin-left: 0;
  }
}

.logo-align {
  @media screen and (max-width: 992px) {
    text-align: center;
    margin-top: 25px;
  }
}
