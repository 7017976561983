a,
a:hover {
  color: white;
}

.right-img-detail-section {
  margin: 4em 4em 0em 4em;
  padding: 2em 6em 2em 6em;
  @media screen and (max-width: 1800px) {
    padding: 2em 7em;
  }
  @media screen and (max-width: 1400px) {
    padding: 2em 3em 2em 4em;
  }
  @media screen and (max-width: 1200px) {
    padding: 2em 1em 2em 1em;
  }
  @media screen and (max-width: 992px) {
    margin: 4em 0em 0em 0em;
  }

  .right-section {
    align-items: center;
    justify-content: center;
  }

  .shopiconimg {
    width: 390px;
    height: 69px;
    @media screen and (max-width: 802px) {
      width: 240px;
      height: 45px;
    }
  }

  .title {
    font-family: 'Manrope';
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    @media screen and (max-width: 992px) {
      font-size: 36px;
      line-height: 46px;
    }
    @media screen and (min-width: 350px) and (max-width: 450px) {
      width: 335px;
    }
    @media screen and (min-width: 1024px) {
      width: 450px;
    }
  }

  .center {
    justify-content: space-around;
    padding: 0.6em;
    text-align: center;
  }

  .text {
    font-family: 'Manrope';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: start;
    color: white;
    opacity: 0.75;
    @media screen and (max-width: 992px) {
      font-size: 17px;
    }
    @media screen and (min-width: 340px) and (max-width: 400px) {
      width: 325px;
    }
  }

  .text-section {
    margin-top: 3.9em;
    @media screen and (max-width: 992px) {
      margin-bottom: 50px;
      margin-top: 10px !important;
    }
  }
}

.discover-match {
  @media screen and (min-width: 992px) {
    margin: 1em 4em 0;
  }
}

.brands-details {
  padding: 1em;
  overflow: hidden;
  max-width: 1300px;
  margin: 0 auto;
  @media screen and (max-width: 992px) {
    padding: 0;
  }

  .brand-row {
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
    padding: 3em 0em 0em 0em;
    width: 100%;
    background-size: cover;
    min-height: 720px;
    border-radius: 40px;
    justify-content: center;
    color: white;
    z-index: 5;
    position: relative;
    @media screen and (max-width: 992px) {
      border-radius: 0px;
      padding: 0em 0em 0em 0em;
    }
    @media screen and (min-width: 768px) and (max-width: 992px) {
      min-height: 400px;
      max-height: 550px;
    }
    @media screen and (min-width: 600px) and (max-width: 700px) {
      min-height: 550px;
      max-height: 600px;
    }

    .center {
      justify-content: space-around;
      padding: 0.6em;
      text-align: center;
    }

    .main-heading {
      font-family: 'Manrope';
      font-size: 100px;
      font-style: normal;
      font-weight: 1000;
      line-height: 97px;
      letter-spacing: 0px;
      text-align: left;
      @media screen and (max-width: 1200px) {
        font-size: 80px;
      }
      @media screen and (max-width: 992px) {
        font-size: 44px;
        font-weight: 700;
        line-height: 55px;
      }
    }

    .title {
      margin-top: 1rem;
      font-family: 'Manrope';
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px;
      letter-spacing: 0px;
      text-align: left;
      @media screen and (max-width: 992px) {
        font-size: 19px;
        line-height: 29px;
      }
    }

    .banner-texts {
      padding: 4rem 2rem 1rem;

      @media screen and (min-width: 992px) {
        padding: 4rem 0rem 0rem 6rem;
      }

      @media screen and (min-width: 992px) and (max-width: 1200px) {
        padding: 4rem 1rem 0 2rem;
      }
      @media screen and (min-width: 1300px) and (max-width: 1400px) {
        padding: 4rem 1rem 0 2rem;
      }
    }

    .btnrow {
      margin-top: 3em;
      @media screen and (max-width: 992px) {
        margin-top: 1rem;
      }
    }

    .clickbtnwhite {
      height: 76px;
      width: 202px;
      border-radius: 8px;
      background: white;
      font-family: 'Manrope';
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 23px;
      letter-spacing: 0px;
      text-align: center;
      color: #042237;
      border: none;
      @media screen and (max-width: 992px) {
        width: 150px;
        height: 60px;
        font-size: 16px;
      }
    }

    .bg-ellipse {
      position: relative;
      background: url('../../../../../public/images/bannerEllipse.png') 10% top
        no-repeat;
      background-size: 578px, 578px;
      height: 600px;
      padding: 0;
      @media screen and (max-width: 600px) {
        background: url('../../../../../public/images/roundMob.png') right
          bottom no-repeat;
        height: unset;
      }
      @media screen and (min-width: 600px) and (max-width: 992px) {
        background: none;
      }
    }

    .brand-image {
      background-size: 784px, 646px;
      text-align: left;
      margin-top: 80px;
      margin-right: 10px;
      width: 100%;
      @media screen and (max-width: 992px) {
        display: none;
      }
    }

    .brand-image-mob {
      margin: 80px auto 50px;
      display: block;
      @media screen and (min-width: 992px) {
        display: none;
      }
      @media screen and (min-width: 600px) and (max-width: 992px) {
        margin: 150px 0;
        width: 90%;
      }
    }
  }
  .banner-base {
    border-radius: 40px;
    background-size: cover;
    padding: 3em 0em 3em 0em;
    justify-content: center;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.25);
    width: 100%;
    margin-top: -50px;
    z-index: 2;
    @media screen and (max-width: 992px) {
      border-radius: 0px;
      min-height: auto;
    }

    .bg-arrow {
      width: 100%;
      .base-heading {
        font-family: 'Manrope';
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 64px;
        letter-spacing: 0px;
        text-align: center;
        margin: 5rem 0 1rem;
        @media screen and (max-width: 992px) {
          font-size: 33px;
          line-height: 46px;
          margin: 50px 30px 30px;
        }
      }

      .base-text {
        font-family: 'Manrope';
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0px;
        text-align: center;
        opacity: 0.75;
        margin-bottom: 40px;
        @media screen and (max-width: 992px) {
          font-size: 18px;
          line-height: 28px;
          padding: 0 25px;
        }
      }
      @media screen and (min-width: 1024px) {
        width: 990px;
      }
    }
  }
}

.banner-details {
  padding: 1em;
  overflow: hidden;
  max-width: 1300px;
  margin: 0 auto;
  @media screen and (max-width: 992px) {
    padding: 0;
  }

  .banner-row {
    position: relative;
    z-index: 3;
    background: url('../../../../assets/images/discathletemain.svg');
    background-repeat: no-repeat;
    border-radius: 40px;
    padding: 1em 0em 0em 0em;
    width: 100%;
    background-size: cover;
    height: fit-content;
    color: #042237;

    @media screen and (max-width: 992px) {
      background: url('../../../../assets/images/discathletemobile.svg');
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 0px;
      padding: 0em 0em 0em 0em;
    }

    .center {
      justify-content: space-around;
      padding: 0.6em;
      text-align: center;
    }

    .main-heading {
      font-family: 'Manrope';
      font-size: 100px;
      font-style: normal;
      font-weight: 800;
      line-height: 97px;
      letter-spacing: 0;
      text-align: left;
      color: #042237;
      z-index: 3;
      position: relative;
      @media screen and (max-width: 992px) {
        font-size: 44px;
        font-weight: 700;
        line-height: 55px;
        max-width: 300px;
      }

      @media screen and (min-width: 1024px) {
        width: 650px;
      }
    }

    .title {
      font-family: 'Manrope';
      font-size: 33px;
      font-style: normal;
      font-weight: 500;
      line-height: 35px;
      letter-spacing: 0px;
      text-align: left;
      @media screen and (max-width: 992px) {
        font-size: 24px;
      }
    }

    .banner-texts {
      padding-left: 6em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: 992px) {
        padding: 3rem 1.5rem;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }
      @media screen and (max-width: 1200px) {
        padding: 3rem 2rem;
      }
      @media screen and (min-width: 1400px) and (max-width: 1500px) {
        padding: 3.5em;
      }
    }

    .bg-ellipse {
      position: relative;
      min-height: 679px;
      @media screen and (max-width: 767px) {
        background: url('../../../../assets/images/bannerEllipseImgMob.svg')
          right bottom no-repeat;
        bottom: 0;
        min-height: 350px;
        overflow: visible;
        padding-right: calc(var(--bs-gutter-x) * 0);
        padding-left: calc(var(--bs-gutter-x) * 0);
      }
      @media screen and (min-width: 768px) and (max-width: 992px) {
        min-height: 330px;
        background: none;
      }

      .bannerImg {
        @media screen and (max-width: 992px) {
          position: relative;
          background: url('../../../../../public/images/bannerGirlImgMob.png')
            bottom right no-repeat;
          bottom: 0;
          min-height: 350px;
          overflow: visible;
          padding-right: calc(var(--bs-gutter-x) * 0);
          padding-left: calc(var(--bs-gutter-x) * 0);
        }
        @media screen and (min-width: 768px) and (max-width: 992px) {
          min-height: 450px;
        }
      }
    }
    .banner-girl-image {
      position: relative;
      z-index: 1;
      &:after {
        background-image: url('/assets/images/bannerGirlImg.png');
        display: block;
        height: 100%;
        width: 62%;
        z-index: 2;
        position: absolute;
        background-position: right bottom;
        background-size: contain;
        display: block;
        right: 0;
        background-repeat: no-repeat;
        @media (min-width: 992px) {
          content: '';
        }
      }
    }

    .circle-banner-bg {
      position: absolute;
      z-index: 1;
      width: 90%;
      height: 90%;
      @media screen and (max-width: 767.96px) {
        display: none;
      }
      @media screen and (min-width: 768px) and (max-width: 992px) {
        z-index: 0;
        width: 100%;
        top: 5%;
      }
      @media screen and (min-width: 995px) and (max-width: 1300px) {
        top: 10%;
      }
    }

    .btnrow {
      margin-top: 4em;
      @media screen and (max-width: 992px) {
        margin-top: 2em !important;
      }
    }

    .clickbtnbluecontain {
      padding: 0;
      margin-left: 10px;
      @media screen and (max-width: 992px) {
        padding-left: 0;
        margin-left: 0;
      }
    }

    .clickbtnblue {
      height: 76px;
      width: 202px;
      border-radius: 8px;
      background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
      font-family: 'Manrope';
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 23px;
      letter-spacing: 0;
      text-align: center;
      color: white;
      border: none;
      box-shadow: 0px 10px 20px rgba(31, 39, 167, 0.2);
      @media screen and (max-width: 992px) {
        height: 60px;
        font-size: 15px;
        line-height: 21px;
        width: 160px;
      }
    }

    .calendly-contain {
      @media screen and (min-width: 1400px) and (max-width: 1600px) {
        margin-left: 30px;
      }
      @media screen and (max-width: 992px) {
        // margin: 0 0 0 15px;
        // width: -webkit-fill-available;
        // width: -moz-available;
      }
    }

    .clickbtnwhite {
      height: 76px;
      width: 202px;
      border-radius: 8px;
      background: white;
      font-family: 'Manrope';
      font-size: 19px;
      font-style: normal;
      font-weight: 800;
      line-height: 23px;
      letter-spacing: 0;
      text-align: center;
      color: #042237;
      border: none;
      position: relative;
      z-index: 5;
      box-shadow: 0px 10px 20px rgba(31, 39, 167, 0.2);
      // @media screen and (max-width: 801px) {
      //   margin-top: 1em;
      //   margin-left: 6px !important;
      // }
      @media screen and (max-width: 992px) {
        // height: 60px;
        // width: 150px;
        // font-size: 16px;
        // line-height: 23px;
        // margin-left: -10px;
        display: none;
      }
      @media screen and (min-width: 2800px) {
        margin-left: -20px;
      }
    }
  }
  .banner-base {
    margin-top: -50px;
    z-index: 2;
    border-radius: 40px;
    background-size: cover;
    padding: 3em 0em 3em 0em;
    justify-content: center;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.25);
    width: 100%;
    min-height: 464px;
    margin-left: 0;
    margin-right: 0;
    @media screen and (max-width: 992px) {
      border-radius: 0px;
      margin: 0;
      min-height: 350px;
    }

    .bg-arrow {
      background: url('../../../../../public/images/bannerBaseLight.svg') center
        center no-repeat;
      @media screen and (max-width: 992px) {
        background: url('../../../../../public/images/bannerBaseLight.svg')
          center no-repeat;
        background-size: 16%;
      }
      @media screen and (max-width: 767.98px) {
        background-size: 22%;
      }
      @media screen and (min-width: 1024px) {
        width: 1010px;
      }
    }

    .base-heading {
      font-family: 'Manrope';
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 64px;
      letter-spacing: 0px;
      text-align: center;
      margin: 5rem 0 1rem;
      @media screen and (max-width: 992px) {
        font-size: 36px;
        line-height: 46px;
        margin: 0 0 30px;
      }
    }

    .base-text {
      font-family: 'Manrope';
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px;
      letter-spacing: 0px;
      text-align: center;
      opacity: 0.75;
      @media screen and (max-width: 992px) {
        font-size: 18px;
        line-height: 28px;
        padding: 0 24px;
      }
    }
  }
}

.maximize-earning-bg {
  background: url('../../../../../public/images/maximizaearnbg.svg') no-repeat;
  background-size: cover;
  @media screen and (max-width: 992px) {
    background: url('../../../../../public/images/getStartedBgMob.png')
      no-repeat;
    background-size: cover;
    background-position: bottom;
  }
  .row {
    margin: 0;
  }
  .maximize-earnings {
    padding: 100px 0;
    margin: 0em 0em 0em 0em;
    color: #042237;
    width: 100%;
    // min-height: 699px;
    @media screen and (max-width: 992px) {
      padding: 50px 30px;
    }
    @media screen and (min-width: 3600px) and (max-width: 4000px) {
      max-width: 1660px;
      margin: 0 auto;
    }

    .bglight {
      background: url('../../../../../public/images/maximizeearnbgarrow.svg')
        top no-repeat;
      width: 100%;
      height: auto;
    }

    .title {
      font-family: 'Manrope';
      font-size: 50px;
      font-style: normal;
      font-weight: 800;
      line-height: 64px;
      letter-spacing: 0px;
      text-align: center;
      @media screen and (max-width: 992px) {
        font-size: 36px;
        font-weight: 700;
        line-height: 46px;
        padding-bottom: 0 !important;
        // margin-top: 30px;
      }
    }
    .subtext {
      font-family: 'Manrope';
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: center;
      @media screen and (max-width: 992px) {
        font-size: 17px;
        line-height: 27px;
      }
    }

    .center {
      justify-content: space-around;
      padding: 0 0.6em 0.6em;
      text-align: center;
    }

    .fees-text {
      font-size: 35px;
      padding-top: 0;
      @media screen and (max-width: 992px) {
        margin-top: 0;
        padding-top: 0;
        font-size: 28px;
        line-height: 36px;
      }
    }

    .num-text {
      font-family: 'Manrope';
      font-size: 150px;
      font-style: normal;
      font-weight: 700;
      line-height: 139px;
      letter-spacing: 0px;
      text-align: center;
      @media screen and (max-width: 992px) {
        font-size: 100px;
        line-height: 112px;
      }
    }

    .buttons-contain {
      @media screen and (max-width: 992px) {
        padding: 1em 0;
      }
    }

    .clickbtnblue {
      text-transform: uppercase;
      height: 76px;
      width: 202px;
      border-radius: 8px;
      background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
      font-family: 'Manrope';
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 23px;
      letter-spacing: 0px;
      text-align: center;
      color: white;
      box-shadow: 0px 10px 20px rgba(31, 39, 167, 0.2);
      border: none;
      @media screen and (max-width: 992px) {
        height: 60px;
        width: 150px;
        font-size: 16px;
        line-height: 23px;
      }
    }

    .clickbtnwhite {
      height: 76px;
      width: 202px;
      border-radius: 8px;
      background: white;
      font-family: 'Manrope';
      font-size: 18px;
      font-style: normal;
      font-weight: 1000;
      line-height: 23px;
      letter-spacing: 0px;
      text-align: center;
      color: #042237;
      text-transform: uppercase;
      box-shadow: 0px 10px 20px rgba(31, 39, 167, 0.2);
      @media screen and (max-width: 992px) {
        height: 60px;
        width: 150px;
        font-size: 16px;
        line-height: 23px;
      }
    }
  }
}

.section-get-started {
  padding: 6em 0;
  margin: 5em 0em 0em 0em;
  background: url('../../../../../public/images/getstartedbg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  color: #042237;
  width: 100%;
  @media screen and (max-width: 992px) {
    margin: 0;
    background: url('../../../../../public/images/getStartedBgMob.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 4em;
  }

  .center {
    justify-content: space-around;
    text-align: center;
  }

  .title {
    font-family: 'Manrope';
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: 113px;
    letter-spacing: 0;
    @media screen and (max-width: 669px) {
      font-size: 36px;
      line-height: 46px;
      width: 300px;
      margin: 0 auto;
    }
  }

  .texts {
    font-family: 'Manrope';
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    @media screen and (max-width: 669px) {
      font-size: 17px;
      line-height: 29px;
      font-weight: 500;
      padding: 20px 20px 0;
    }
  }

  .clickbtn {
    height: 76px;
    width: 202px;
    border-radius: 8px;
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
    font-family: 'Manrope';
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: center;
    color: white;
    margin-top: 40px;
    box-shadow: 0px 10px 20px rgba(31, 39, 167, 0.2);
    border: none;
    cursor: pointer;
    @media screen and (max-width: 992px) {
      height: 60px;
      width: -webkit-fill-available;
      width: -moz-available;
      font-size: 16px;
      margin: 40px 20px 0;
    }
    @media screen and (min-width: 768px) and (max-width: 992px) {
      width: 202px;
    }
  }

  .demo-contain {
    display: flex;
    justify-content: center;
    margin: 40px 0 20px;
  }
}

.section-howit-works {
  padding: 2em 4em 2.5em 4em;
  margin: 1em 5.5em 0em 5.5em;
  @media screen and (max-width: 1600px) {
    padding: 2em 2.5em 2.5em 2.5em;
    margin: 1em 4em 0em 4em;
  }
  @media screen and (max-width: 992px) {
    margin: 2em 0em 0em 0em;
    padding: 2em 1em;
  }

  .heading {
    justify-content: center;
    font-family: 'Manrope';
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 113px;
    letter-spacing: 0px;
    text-align: center;
    @media screen and (max-width: 992px) {
      font-size: 36px;
      line-height: 46px;
    }
  }

  .center {
    justify-content: space-around;
    padding: 0.6em;
    text-align: center;
  }

  .cols {
    padding: 0;
    @media screen and (max-width: 992px) {
      padding: 1em 0;
    }
  }

  .imgRow {
    padding: 1em;
    @media screen and (max-width: 992px) {
      padding-bottom: 10px;
    }
  }

  .title {
    font-family: 'Manrope';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;
    min-height: 95px;
    @media screen and (max-width: 550px) {
      font-size: 22px;
      width: 270px;
      height: unset;
      margin: 0 auto;
      padding-top: 0;
      min-height: auto;
    }
    @media screen and (min-width: 550px) and (max-width: 992px) {
      font-size: 22px;
      width: unset;
      height: auto;
      min-height: auto;
      padding-top: 0;
    }
  }

  .title-text {
    padding: 0.6em;
  }

  .texts {
    font-family: 'Manrope';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    opacity: 0.75;
    padding: 0 1em;
  }
}

.left-img-detail-section {
  background: rgba(0, 0, 0, 0.25);
  // margin: 2.4em 7em 0;
  border-radius: 40px;
  padding: 2em 6em 2em 6em;
  background-size: cover;
  max-height: 80%;
  max-width: 1300px;
  margin: 0 auto;
  @media screen and (max-width: 1400px) {
    padding: 2em 8em 2em 2em;
  }
  @media screen and (max-width: 1200px) {
    padding: 2em 1em 2em 1em;
  }
  @media screen and (max-width: 992px) {
    // margin: 4em 0em 0em 0em;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 0;
    margin-bottom: 20px;
  }

  .clickbtnwhite {
    height: 76px;
    width: 202px;
    border-radius: 8px;
    background: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    color: #042237;
    border: none;
    @media screen and (max-width: 992px) {
      width: 150px;
      height: 60px;
      font-size: 16px;
    }
  }

  .left-section {
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 1300px) and (max-width: 1390px) {
      justify-content: unset;
    }
  }
  .shopiconimg {
    width: 390px;
    height: 69px;
    @media screen and (max-width: 802px) {
      width: 240px;
      height: 40px;
      margin-top: 0.6em;
    }
  }

  .any-image {
    position: relative;
    max-width: 100%;
    top: -55px;
    @media screen and (max-width: 420px) {
      margin-top: -20%;
    }
    @media screen and (min-width: 1200px) {
      max-width: 90%;
      top: -55px;
    }
    @media screen and (max-width: 992px) {
      width: 90%;
      margin: 0 25px;
    }
  }

  .title {
    font-family: 'Manrope';
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    @media screen and (max-width: 992px) {
      font-size: 36px;
      line-height: 46px;
      max-width: 315px;
      margin-top: 30px;
    }
  }

  .center {
    justify-content: space-around;
    padding: 0em 0em 0.6em 0em;
    text-align: center;
  }

  .text {
    font-family: 'Manrope';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
    opacity: 0.75;
    width: 450px;

    @media screen and (max-width: 350px) {
      width: 280px;
    }
    @media screen and (min-width: 350px) and (max-width: 560px) {
      width: 320px;
    }
    @media screen and (max-width: 992px) {
      font-size: 17px;
      width: auto;
    }
  }
}

.no-bg {
  background: none;
  border-radius: 0;
}

.why-athlete-up {
  max-width: 1157px;
  margin: 80px auto 0;
  padding: 0 50px;
  @media screen and (max-width: 992px) {
    padding: 0 30px;
  }

  .lists {
    @media screen and (max-width: 992px) {
      padding-left: 0 !important;
    }
  }

  .title {
    font-family: 'Manrope';
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 40px;
    @media screen and (max-width: 669px) {
      font-size: 36px;
      line-height: 46px;
    }
    @media screen and (max-width: 992px) {
      margin-bottom: 0;
    }
  }

  .imgIcon {
    @media (min-width: 992px) {
      margin-top: 0.9em;
    }
  }

  .lists-item {
    @media screen and (max-width: 992px) {
      padding: 20px 0;
    }
  }

  .athlete-up-content {
    padding: 0 2em 1em 0;
  }

  .sub-title {
    font-family: 'Manrope';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0px;
    @media screen and (max-width: 669px) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  .center {
    justify-content: center;
    padding: 0.6em;
    // text-align: center;
  }

  .texts {
    margin-top: 1rem;
    opacity: 0.75;
  }

  .text-section {
    margin-top: 3.9em;
  }
}

.bgWhite {
  background: url('../../../../assets/images/meetStarsRightBottombg.svg') right
      80% no-repeat,
    url('../../../../assets/images/meetStarsLeftTopbg.svg') left 30% no-repeat;
  background-color: #fefefe;
  @media screen and (max-width: 600px) {
    background: url('../../../../assets/images/meetStarsLeftTopbg.svg') left 30%
        no-repeat,
      url('../../../../assets/images/meetStarsRightBottombg.svg') 0% bottom
        no-repeat;
    background-color: #fefefe;
  }
  @media screen and (min-width: 601px) and (max-width: 992px) {
    background: #fefefe;
  }
}

.sectionbg {
  margin-top: 4em;
  min-height: 707px;
  color: black;
  justify-content: center;
  margin: 0 auto;
  @media screen and (max-width: 692px) {
    padding: 1em;
  }
  @media screen and (min-width: 700px) and (max-width: 992px) {
    padding: 1em 6em;
  }
  @media screen and (min-width: 3600px) and (max-width: 4000px) {
    margin: 0 auto;
    max-width: 1000px;
  }

  .meet-our-stars {
    position: relative;
    background-size: auto, auto;
    min-height: 769px;
    padding: 50px 0;
    // @media screen and (min-width: 400px) and (max-width: 450px) {
    //   margin: 0 0 0 20px;
    // }
    @media screen and (max-width: 992px) {
      background: none;
    }

    .title {
      font-family: 'Manrope';
      font-size: 50px;
      font-style: normal;
      font-weight: 800;
      line-height: 68px;
      letter-spacing: 0px;
      justify-content: space-around;
      color: #042237;
      text-align: center;
      @media screen and (max-width: 992px) {
        font-size: 36px;
        font-weight: bold;
        line-height: 46px;
      }
    }

    .texts {
      font-family: 'Manrope';
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: center;
      color: #435969;
      @media screen and (max-width: 992px) {
        font-size: 19px;
        line-height: 30px;
      }
    }

    .center {
      justify-content: space-around;
      padding: 0.6em;
      text-align: center;
    }

    .athlete-grid {
      max-width: 1200px;
      margin: 0 auto;
      @media screen and (max-width: 992px) {
        &.row {
          --bs-gutter-x: 1rem;
        }
      }

      @media screen and (min-width: 600px) and (max-width: 650px) {
        margin-left: 80px;
        width: 450px;
        --bs-gutter-x: 1rem;
      }
      @media screen and (min-width: 651px) and (max-width: 992px) {
        --bs-gutter-x: 1rem;
      }
      @media screen and (min-width: 992px) and (max-width: 1200px) {
        padding: 0 50px;
      }
    }

    .card {
      // height: 400px;
      // width: 270px;
      border-radius: 20px;
      border: none;
      box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.05);
      margin-bottom: 0;
      height: 100%;
      > img,
      .placeholder-athlete-icon {
        border-radius: 20px;
        object-fit: cover;
        object-position: right top;
        background-color: #ddd;
        height: 150px;
        @media (min-width: 991px) {
          height: 250px;
        }
      }
      .placeholder-athlete-icon {
        background-size: cover;
      }
      .card-title {
        font-weight: 700;
        color: #042237;
        @media screen and (max-width: 992px) {
          font-size: 17px;
        }
      }
      .card-text {
        font-family: 'Manrope';
        color: rgba(67, 89, 105, 1);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        @media (min-width: 991px) {
          font-size: 15px;
          line-height: 22px;
        }
      }

      .listingsCount {
        font-family: 'Manrope';
        font-weight: 600;
        color: #042237;
        font-size: 16px;
        margin-left: 5px;
        @media screen and (max-width: 992px) {
          font-size: 14px;
        }
      }
      .card-body {
        display: flex;
        flex-direction: column;
      }
      .sport-place {
        color: #435969;
        line-height: 22px;
        @media screen and (max-width: 992px) {
          font-size: 13px;
          height: 40px;
          line-height: 20px;
        }
      }
    }

    .clickbtnblue {
      height: 60px;
      width: 200px;
      border-radius: 8px;
      background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
      box-shadow: 0px 10px 20px rgba(31, 39, 167, 0.2);
      font-family: 'Manrope';
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 23px;
      letter-spacing: 0px;
      text-align: center;
      color: white;
      margin: 40px 0 0;
      border: none;
    }
  }
}

.brand-detail-section {
  padding: 5em;
  margin: 3em 4em 0em 4em;
  // background: url('/images/leftImgSectionBg.svg');
  background-repeat: no-repeat;
  justify-content: center;
  min-height: 464px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  @media screen and (max-width: 992px) {
    border-radius: 0px;
    margin: 0em 0em 0em 0em;
    padding: 24px;
  }

  .title {
    font-family: 'Manrope';
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: center;
    width: 880px;
    @media screen and (max-width: 992px) {
      font-size: 36px;
      line-height: 46px;
    }
    @media screen and (max-width: 669px) {
      width: 350px;
    }
  }

  .center {
    justify-content: space-around;
    padding: 0.6em;
    text-align: center;
  }

  .texts {
    font-family: 'Manrope';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    opacity: 0.75;
  }
}

.calendly-badge-widget {
  position: relative !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: unset !important;

  .calendly-badge-content {
    font-size: 18px !important;
    font-style: normal;
    font-weight: 800 !important;
    line-height: 23px;
    letter-spacing: 0;
    text-align: center !important;
    border: none;
    height: 76px !important;
    width: 202px !important;
    border-radius: 8px !important;
    font-family: 'Manrope' !important;
    white-space: nowrap;
    box-shadow: 0px 10px 20px rgba(31, 39, 167, 0.2) !important;
    @media screen and (max-width: 992px) {
      height: 60px !important;
      width: 160px !important;
    }
  }
}

.calendly-overlay .calendly-close-overlay {
  @media screen and (max-width: 992px) {
    background-color: silver;
    background-color: lightblue;
  }
}
