select,
input:focus ~ .floating-label,
input:not(:focus):valid ~ .floating-label,
input:-webkit-autofill ~ .floating-label,
input:-webkit-autofill:active ~ .floating-label {
  margin-top: 8px;
  left: 63em;
  opacity: 1;
  font-family: 'Manrope';
  font-size: 12px;
  font-weight: 600;
  line-height: 16.5px;
  font-style: normal;
  color: #435969;

  @media screen and (max-width: 2800px) {
    left: 52.5em;
  }

  @media screen and (max-width: 2500px) {
    left: 44.5em;
  }

  @media screen and (max-width: 2200px) {
    left: 14.4%;
  }

  @media screen and (max-width: 2000px) {
    left: 15.4%;
  }

  @media screen and (max-width: 1800px) {
    left: 10%;
  }

  @media screen and (max-width: 1600px) {
    left: 7.1%;
  }

  @media screen and (max-width: 992px) {
    left: 13.4%;
  }

  @media screen and (max-width: 420px) {
    left: 14.4%;
  }
}

label.floating-label {
  margin-top: 0;
  background: transparent;
}

.input {
  border-radius: 8px;
  min-width: 100%;
  height: 64px;
  color: #435968;
  border: 1px solid #d3dce1;
  padding-top: 20px;
  padding-left: 3%;
  font-family: 'Manrope';
  font-size: 18px;
  font-weight: 500;
  line-height: 24.59px;
  font-style: normal;
  text-align: left;

  @media screen and (max-width: 992px) {
    padding-left: 2.5%;
    height: 60px;
    font-size: 17px;
    line-height: 23.22px;
  }
}

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 42em;
  margin-top: 20px;
  transition: 0.2s ease all;
  color: #435968;
  font-family: 'Manrope';
  font-size: 18px;
  font-weight: 500;
  line-height: 24.59px;
  font-style: normal;
  text-align: left;

  @media screen and (max-width: 3900px) {
    left: 68em;
  }

  @media screen and (max-width: 3200px) {
    left: 46em;
  }

  @media screen and (max-width: 2800px) {
    left: 35em;
  }

  @media screen and (max-width: 2500px) {
    left: 29.6em;
  }

  @media screen and (max-width: 2200px) {
    left: 14.4%;
  }

  @media screen and (max-width: 2000px) {
    left: 15.4%;
  }

  @media screen and (max-width: 1800px) {
    left: 10%;
  }

  @media screen and (max-width: 1600px) {
    left: 7.1%;
  }

  @media screen and (max-width: 992px) {
    left: 13.4%;
    font-size: 17px;
    line-height: 23.22px;
  }

  @media screen and (max-width: 420px) {
    left: 14.4%;
    font-size: 14px;
  }
}

.input2:focus ~ .floating-label-2,
.input2:not(:focus):valid ~ .floating-label-2 {
  margin-top: 8px;
  left: 39.5%;
  opacity: 1;
  font-family: 'Manrope';
  font-size: 12px;
  font-weight: 600;
  line-height: 16.5px;
  font-style: normal;
  color: #4f3fcc;

  @media screen and (max-width: 2800px) {
    left: 38.4%;
  }

  @media screen and (max-width: 2500px) {
    left: 37.7%;
  }

  @media screen and (max-width: 2200px) {
    left: 34.5%;
  }

  @media screen and (max-width: 2000px) {
    left: 35%;
  }

  @media screen and (max-width: 1800px) {
    left: 32.5%;
  }

  @media screen and (max-width: 1600px) {
    left: 31.4%;
  }

  @media screen and (max-width: 1200px) {
    left: 7.2%;
  }

  @media screen and (max-width: 992px) {
    left: 13.3%;
  }

  @media screen and (max-width: 420px) {
    left: 14.3%;
  }
}

.input2 {
  border-radius: 8px;
  min-width: 100%;
  height: 64px;
  color: #435968;
  border: 1px solid #d3dce1;
  padding-top: 20px;
  padding-left: 5.7%;
  font-family: 'Manrope';
  font-size: 18px;
  font-weight: 500;
  line-height: 24.59px;
  font-style: normal;
  text-align: left;

  @media screen and (max-width: 1200px) {
    padding-left: 3%;
  }

  @media screen and (max-width: 992px) {
    padding-left: 2.6%;
    height: 60px;
    font-size: 17px;
    line-height: 23.22px;
  }
}

.floating-label-2 {
  position: absolute;
  pointer-events: none;
  left: 39.5%;
  margin-top: 20px;
  transition: 0.2s ease all;
  color: #435968;
  font-family: 'Manrope';
  font-size: 18px;
  font-weight: 500;
  line-height: 24.59px;
  font-style: normal;
  text-align: left;

  @media screen and (max-width: 2800px) {
    left: 38.6%;
  }

  @media screen and (max-width: 2500px) {
    left: 38%;
  }

  @media screen and (max-width: 2200px) {
    left: 34.5%;
  }

  @media screen and (max-width: 2000px) {
    left: 35%;
  }

  @media screen and (max-width: 1800px) {
    left: 32.5%;
  }

  @media screen and (max-width: 1600px) {
    left: 31.4%;
  }

  @media screen and (max-width: 1200px) {
    left: 7.2%;
  }

  @media screen and (max-width: 992px) {
    left: 13.3%;
    font-size: 17px;
    line-height: 23.22px;
  }

  @media screen and (max-width: 420px) {
    left: 14.3%;
    font-size: 14px;
  }
}

.login-main {
  padding: 4rem 2rem 4rem 2rem;

  @media screen and (max-width: 767px) {
    padding: 1rem;
  }

  .login-section {
    align-items: center;
    justify-content: center;
    padding: 50px 0;

    @media (min-width: 991px) {
      padding: 0;
    }
  }

  .login-title {
    font-family: 'Manrope';
    font-size: 50px;
    font-weight: 700;
    line-height: 64px;
    font-style: normal;
    text-align: left;
    color: #042237;

    @media screen and (max-width: 992px) {
      font-size: 36px;
      line-height: 46px;
    }
  }

  .login-texts {
    font-family: 'Manrope';
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    font-style: normal;
    text-align: left;
    color: #435969;

    @media screen and (max-width: 992px) {
      font-size: 16px;
      line-height: 26px;
      font-weight: 600;
    }
  }

  .forgot-texts {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    font-style: normal;
    text-align: right;
    color: #435969;
    margin-top: 15px;

    @media screen and (max-width: 992px) {
      font-size: 14px;
      line-height: 19px;
    }
  }

  .clickbtn {
    border: none;
    min-width: 100%;
    height: 66px;
    border-radius: 8px;
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
    font-family: 'Manrope';
    font-size: 18px;
    font-weight: 800;
    line-height: 23px;
    text-align: center;
    color: white;

    @media screen and (max-width: 992px) {
      height: 60px;
    }
  }

  .no-account {
    font-family: 'Manrope';
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #435969;
    cursor: pointer;

    @media screen and (max-width: 992px) {
      font-size: 16px;
      font-weight: 400px;
    }
  }

  .color {
    font-family: 'Manrope';
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: #4f3fcc;

    @media screen and (max-width: 992px) {
      font-weight: 500;
      font-size: 16px;
    }
  }

  .boy-image {
    position: relative;
    max-width: 570px;
    filter: drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.1));

    @media screen and (max-width: 992px) {
      max-width: 500px;
    }

    @media screen and (max-width: 669px) {
      display: none;
    }
  }

  .bg {
    position: relative;
    background: url('../../../../../public/images/loginrightbackground.svg')
      center no-repeat;
    background-size: cover;
    max-height: 720px;
    border-radius: 40px;
    text-align: end;

    @media screen and (max-width: 669px) {
      background: none;
      max-height: 0px;
    }
  }

  .info-block {
    background-color: rgba(0, 227, 222, 0.2);
    height: 79px;
    width: 170px;
    position: absolute;
    text-align: left;
    border-radius: 15px 0px 0px 15px;
    top: 65px;
    right: 0px;
    padding: 10px 10px 10px 0px;

    @media screen and (max-width: 669px) {
      display: none;
    }
  }

  .nameage {
    font-family: 'Manrope';
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    font-style: normal;
    text-align: center;
    color: #042237;
  }

  .playertitle {
    font-family: 'Manrope';
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    font-style: normal;
    text-align: center;
    color: #435969;
  }
}

.register-main-bg {
  background: white;

  .register-main {
    padding: 8rem 2rem 4rem 2rem;

    // background: white;
    &.register-purchaser .register-section {
      .selectedTab,
      .ifSelectedbg,
      .ifSelected,
      .unselectedTab {
        width: 100%;
      }
    }

    @media (min-width: 991px) {
      padding: 4rem 2rem 4rem 2rem;
    }

    .register-section {
      align-items: center;
      justify-content: center;

      .register-title {
        font-family: 'Manrope';
        font-size: 50px;
        font-weight: 700;
        line-height: 64px;
        font-style: normal;
        text-align: left;
        color: #042237;

        @media screen and (max-width: 992px) {
          font-size: 36px;
          line-height: 46px;
        }
      }

      .register-texts {
        font-family: 'Manrope';
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        font-style: normal;
        text-align: left;
        color: #435969;

        @media screen and (max-width: 992px) {
          font-size: 16px;
          line-height: 26px;
          font-weight: 600;
        }
      }

      .clickbtn {
        min-width: 100%;
        height: 66px;
        border-radius: 8px;
        background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
        font-family: 'Manrope';
        font-size: 18px;
        font-weight: 800;
        line-height: 23px;
        text-align: center;
        color: white;

        @media screen and (max-width: 992px) {
          height: 60px;
        }
      }

      .no-account {
        font-family: 'Manrope';
        font-size: 17px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        color: #435969;
        cursor: pointer;

        @media screen and (max-width: 992px) {
          font-size: 16px;
          font-weight: 400px;
        }
      }

      .color {
        font-family: 'Manrope';
        font-size: 17px;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
        color: #4f3fcc;

        @media screen and (max-width: 992px) {
          font-weight: 500;
          font-size: 16px;
        }
      }

      .bg {
        position: relative;
        background: url('../../../../../public/images/loginrightbackground.svg')
          center no-repeat;
        background-size: cover;
        max-height: 720px;
        border-radius: 40px;
        text-align: end;

        @media screen and (max-width: 669px) {
          background: none;
          min-height: 0px;
        }
      }

      .girl-image {
        position: relative;
        max-width: 540px;
        max-height: 720px;
        filter: drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.1));

        @media screen and (max-width: 992px) {
          max-width: 490px;
        }

        @media screen and (max-width: 669px) {
          display: none;
        }
      }

      .card-section {
        margin-top: 80px;
        margin-bottom: 50px;

        @media screen and (max-width: 1200px) {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }

      .selectedTab {
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 30px;
        padding: 3px;
        height: 221px;
        width: 160px;
        background: linear-gradient(to right, #589cf9, #8df49b);
        cursor: pointer;

        @media screen and (max-width: 1200px) {
          width: 100%;
          height: fit-content;
          border-radius: 20px;
        }
      }

      .ifSelected {
        height: 214.5px;
        width: 153.5px;
        border-radius: 27px;
        background-color: white;

        @media screen and (max-width: 1200px) {
          width: 100%;
          height: fit-content;
          border-radius: 17px;
        }
      }

      .ifSelectedbg {
        text-align: center;
        height: 214.5px;
        width: 153.5px;
        border-radius: 27px;
        background: linear-gradient(
          265.02deg,
          rgba(141, 244, 155, 0.3) 29.32%,
          rgba(88, 156, 249, 0.3) 93.34%
        );
        padding-top: 25px;

        @media screen and (max-width: 1200px) {
          width: 100%;
          height: fit-content;
          padding: 5px;
          border-radius: 17px;
        }
      }

      .unselectedTab {
        text-align: center;
        border: 1px solid #d3dce1;
        padding-top: 25px;
        height: 221px;
        width: 160px;
        border-radius: 30px;
        cursor: pointer;

        @media screen and (max-width: 1200px) {
          width: 100%;
          height: fit-content;
          padding: 5px;
          border-radius: 20px;
        }
      }

      .card-texts {
        margin-top: 30px;
        font-family: 'Manrope';
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
        font-style: normal;
        text-align: center;
        color: #042237;
      }

      .info-block {
        background-color: rgba(0, 227, 222, 0.2);
        height: 79px;
        width: 170px;
        position: absolute;
        text-align: left;
        border-radius: 15px 0px 0px 15px;
        top: 65px;
        right: 0px;
        padding: 10px 10px 10px 0px;

        @media screen and (max-width: 669px) {
          display: none;
        }
      }

      .nameage {
        font-family: 'Manrope';
        font-size: 28px;
        font-weight: 700;
        line-height: 32px;
        font-style: normal;
        text-align: center;
        color: #042237;
      }

      .playertitle {
        font-family: 'Manrope';
        font-size: 15px;
        font-weight: 400;
        line-height: 28px;
        font-style: normal;
        text-align: center;
        color: #435969;
      }

      .policy {
        font-family: 'Manrope';
        font-size: 15px;
        font-weight: 500;
        line-height: 20.49px;
        font-style: normal;
        text-align: center;
        color: #435969;
        opacity: 0.8;
      }

      .terms {
        font-family: 'Manrope';
        font-size: 15px;
        font-weight: 600;
        line-height: 20.49px;
        font-style: normal;
        text-align: center;
        color: #435969;
      }
    }
  }
}

.modtitle {
  font-family: 'Manrope';
  font-size: 42px;
  font-weight: 700;
  line-height: 64px;
  font-style: normal;
  text-align: left;
  color: #042237;

  @media screen and (max-width: 992px) {
    font-size: 32px;
    line-height: 36px;
  }
}

.modtext {
  font-family: 'Manrope';
  font-size: 18px;
  font-weight: 700;
  line-height: 64px;
  font-style: normal;
  text-align: left;
  color: #042237;
}

.msg-section {
  padding: 4rem 0rem 4rem 0rem;
  background-color: white;

  .bg-circle {
    height: 190px;
    width: 190px;
    border-radius: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      355.68deg,
      rgba(219, 230, 234, 0.3) 2.88%,
      rgba(255, 255, 255, 0.3) 113.61%
    );
  }

  .msgicon {
    // margin-top: 32%;
  }

  .smallcircle {
    // position: absolute;
    // top: 34.7%;
    // left: 51.4%;
    height: 41px;
    width: 41px;
    border-radius: 20.5px;
    background-color: #8bf19e;
    color: #ffffff;
    font-family: 'Manrope';
    font-size: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1200px) {
      // top: 38.3%;
    }

    @media screen and (max-width: 992px) {
      // top: 31.8%;
    }
  }

  .title {
    font-family: 'Manrope';
    font-size: 44px;
    font-weight: 700;
    line-height: 55px;
    font-style: normal;
    text-align: center;
    color: #042237;

    @media screen and (max-width: 992px) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  .texts {
    font-family: 'Manrope';
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    font-style: normal;
    color: #435969;

    @media screen and (max-width: 992px) {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
    }
  }

  .clickbtn {
    width: 100%;
    height: 66px;
    border-radius: 8px;
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
    font-family: 'Manrope';
    font-size: 18px;
    font-weight: 800;
    line-height: 23px;
    text-align: center;
    color: white;

    @media screen and (max-width: 992px) {
      height: 60px;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
    }
  }
}

.profile-main {
  background: white;
  padding: 8rem 1rem;

  @media (min-width: 991px) {
    padding: 5rem 2rem 5rem 2rem;
  }

  &.pricing-confirmation {
    .profile-title {
      font-size: 30px;

      @media (min-width: 767px) {
        font-size: 44px;
      }
    }

    .profile-text1 {
      max-width: 687px;
      font-size: 16px;

      @media (min-width: 991px) {
        font-size: 20px;
      }
    }
  }

  &.pricing-comp {
    padding-bottom: 2rem;

    .prbutton,
    .cardSubTexts {
      @media (min-width: 768px) {
        font-size: 18px;
      }
    }

    .monthtext {
      font-size: 16px;
    }

    .content {
      padding-top: 20px;
    }

    .light {
      font-size: 14px;
    }

    .pricetext {
      @media (min-width: 991px) {
        font-size: 30px;
      }
    }
  }

  label {
    width: 100%;
    background-color: transparent;
    margin: 1rem 0 2rem 0;
    cursor: pointer;
  }

  .profile-section {
    align-items: center;
    justify-content: center;
    .ant-checkbox-disabled + span {
      color: #042237;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: #fff;
    }
  }

  .profile-text {
    font-family: 'Manrope';
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    font-style: normal;
    text-align: center;
    color: #435969;

    @media screen and (max-width: 992px) {
      font-size: 16px;
      line-height: 26px;
      font-weight: 600;
    }
  }

  .profile-text1 {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    font-style: normal;
    text-align: center;
    color: #435969;
    max-width: 520px;
    margin: 0 auto;

    @media screen and (max-width: 992px) {
      font-size: 16px;
      line-height: 26px;
      font-weight: 600;
    }
  }

  .profile-title {
    font-family: 'Manrope';
    font-size: 44px;
    font-weight: 700;
    line-height: 55px;
    font-style: normal;
    text-align: center;
    color: #042237;

    @media screen and (max-width: 992px) {
      font-size: 36px;
      line-height: 46px;
    }
  }

  .profilepart-1 {
    align-items: center;
    justify-content: center;

    .profile-input {
      width: 260px;
      display: flex;
      border: 2px dashed #d3dce1;
      border-radius: 24px;
      height: 260px;
      align-items: center;
      margin: 0 auto;

      &.profile-image-holder {
        border: none;
      }

      .change-image-text {
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .ant-upload {
      // height: 100%;
    }

    .ant-upload.ant-upload-select {
      width: 100%;
      // display: flex;
      // align-items: center;
      // justify-content: center;
    }

    img.profile-img-holder {
      width: 260px;
      height: 260px;
      border-radius: 24px;
      object-fit: cover;
      background-color: #000;
    }

    // .profile-pic-upload {
    //   width: 260px;
    //   .ant-upload.ant-upload-select-picture-card {
    //     border: 2px dashed #d3dce1;
    //     border-radius: 24px;
    //     width: 260px;
    //     height: 260px;
    //   }
    // }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding-left: 20px;
    }
  }

  .text {
    font-family: 'Manrope';
    font-size: 18px;
    font-weight: 700;
    line-height: 24.59px;
    font-style: normal;
    text-align: left;
    color: #042237;
    background: none;
    height: auto;
    margin: 0 0 5px;
  }

  .pro-input {
    border-radius: 8px;
    width: 100%;
    height: 64px;
    color: #042237;
    opacity: 1;
    border: 1px solid #d3dce1;
    background-color: transparent;
    padding-left: 20px;
    font-family: 'Manrope';
    font-size: 18px;
    font-weight: 500;
    line-height: 24.59px;
    font-style: normal;
    text-align: left;
    cursor: pointer;

    &.input-lg {
      height: 52px;
    }

    &[readonly] {
      background-color: #e9ecef;
      opacity: 1;
    }
  }

  .pro-input-2,
  .ant-input-textarea textarea {
    border-radius: 8px;
    min-width: 100%;
    height: 88px;
    color: #435968;
    border: 1px solid #d3dce1;
    padding-left: 20px;
    font-family: 'Manrope';
    font-size: 18px;
    font-weight: 500;
    line-height: 24.59px;
    font-style: normal;
    text-align: left;
  }

  .clickbtn {
    width: 100%;
    height: 66px;
    border-radius: 8px;
    background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
    font-family: 'Manrope';
    font-size: 18px;
    font-weight: 800;
    line-height: 23px;
    text-align: center;
    color: white;
    border: none;

    @media screen and (max-width: 992px) {
      height: 60px;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
    }
  }

  .clickbtn2 {
    width: 100%;
    height: 64px;
    border: 1px solid #d3dce1;
    border-radius: 8px;
    font-family: 'Manrope';
    font-size: 18px;
    font-weight: 800;
    line-height: 23px;
    text-align: center;
    color: #435968;

    @media screen and (max-width: 992px) {
      height: 60px;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
    }
  }

  .text1 {
    color: black;
    font-family: 'Manrope';
    font-size: 13px;
    font-weight: 500;
    line-height: 17.76px;
    text-align: center;
  }
}

.padding-main {
  padding: 0;
}

.profile-container {
  max-width: 780px;
  margin: 0 auto;

  .profile-wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
}

.ath-order-table {
  border-radius: 8px;
  border: 1px solid #d9e1e7;
  border-bottom: none;

  thead {
    font-size: 15px;

    th {
      font-weight: 500;
    }
  }
}

.ath-order-table tr td {
  border-bottom: 1px solid #d9e1e7;
}

.coupon-button {
  padding: 0 50px 0;
  @media screen and (max-width: 992px) {
    padding: 0 30px 0;
  }
}
.max-earning-sec {
  max-width: 320px;
  @media (min-width: 992px) {
    max-width: 430px;
  }
}
