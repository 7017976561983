.login-main-bg {
  background: white;
  min-height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-shrink: revert;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 1660px;
  margin: 0 auto;
  .padding-main {
    width: 100%;
  }
}
