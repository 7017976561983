.pro-input {
  border-radius: 8px;
  width: 100%;
  height: 64px;
  color: #042237;
  opacity: 1;
  border: 1px solid #d3dce1;
  background-color: transparent;
  padding-left: 20px;
  font-family: 'Manrope';
  font-size: 18px;
  font-weight: 500;
  line-height: 24.59px;
  font-style: normal;
  text-align: left;
  cursor: pointer;
  &.input-lg {
    height: 64px;
  }
  &[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
}

.custom-select.ant-select .ant-select-selector {
  height: 64px;
}

img.profile-img-holder {
  width: 120px;
  height: 120px;
  border-radius: 24px;
  object-fit: cover;
  background-color: #000;
}

.deliverable-close-button {
  cursor: pointer;
  position: absolute;
  padding: 10px;
  top: 0;
  right: 2%;
}

.loader-contain {
  position: relative;
  height: 200px;
  margin-top: 30px;
}

.mobile-select {
  width: 135px;
}

.desktop-select {
  width: 146px;
}

.mobile-edit {
  width: 46px;
  height: 46px;
}

.dropdown-danger {
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 8px;
    height: 46px;
    background-color: rgba(228, 106, 37, 0.1) !important;
    border: none;
    text-align: center;
    width: 100% !important;
    display: block;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    color: #e46a25 !important;
    border-radius: 8px !important;
  }
}

.archived-label {
  width: 146px;
  padding: 8px;
  height: 46px;
  background-color: #eaecee;
  color: #435969;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
}

.invite-heading {
  @media screen and (max-width: 992px) {
    height: 34px;
  }
}

.custom-select.custom-select-light {
  .ant-select-selection-placeholder {
    padding-top: 15px;
  }
}