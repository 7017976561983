.info-icon {
  color: #435969;
  background-color: #d9e1e7;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  font-size: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.info-text {
  font-size: 13px;
  color: $color-body;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-20-mob {
  @media screen and (max-width: 992px) {
    margin-bottom: 20px !important;
  }
}
.fw-300 {
  font-weight: 300;
}
.card-light {
  background-color: $color-light;
  border-radius: 20px;
}

.bg-light {
  background-color: $color-light !important;
}

.mh-100-px {
  height: 100px;
}

.w-xl-80 {
  @media (min-width: 1200px) {
    max-width: 80%;
  }
}
.mw-770 {
  max-width: 770px;
}
.mw-500 {
  max-width: 500px;
}

.mw-687 {
  max-width: 687px;
}

.font-l {
  font-size: 20px;

  @media (min-width: 771px) {
    font-size: 24px;
  }
  @media (min-width: 991px) {
    font-size: 28px;
  }
}
.color-primary-dark {
  color: $color-grey;
}
.font-xl {
  font-size: 30px;

  @media (min-width: 991px) {
    font-size: 44px;
  }
}

.font-xxl {
  font-size: 36px;

  @media (min-width: 991px) {
    font-size: 55px;
  }
}

.font-30 {
  font-size: 20px;

  @media (min-width: 991px) {
    font-size: 30px;
  }
}

.font-22 {
  font-size: 20px;

  @media (min-width: 991px) {
    font-size: 22px;
  }
}

.mh-100-vh {
  height: 100vh;
}

.title {
  font-size: 16px;

  @media (min-width: 991px) {
    font-size: 18px;
  }
}

.header-section {
  margin-bottom: 35px;

  @media (min-width: 991px) {
    margin-bottom: 54px;
  }
}

.font-m {
  font-size: 16px;
}

.font-15 {
  font-size: 15px !important;
}

.font-20 {
  font-size: 16px;

  @media (min-width: 991px) {
    font-size: 20px;
  }
}

.font-18 {
  font-size: 18px;
}

.font-24 {
  font-size: 24px;
}

.card-title {
  font-size: 19px;

  @media (min-width: 991px) {
    font-size: 18px;
  }
}

.zIndex5 {
  z-index: 5;
}

.overflowx-scroll {
  overflow-x: scroll;
}
.d-content {
  display: contents;
}

.mw-160 {
  max-width: 160px;
}
.list-title {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 5px;
  color: $color-grey;
  font-weight: 700;
  @media (min-width: 767px) {
    line-height: 32px;
  }
}

.line-ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.line-ellipse-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.line-ellipse-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}
.h-68 {
  height: 68px;
}

.line-ellipse-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.color-muted {
  color: $color-body;
}
.color-green {
  color: $color-green;
}
.font-resize-m {
  @media (max-width: 767px) {
    p,
    span {
      font-size: 12px !important;
    }
    img {
      width: 10px;
    }
  }
}
.color-dark {
  color: $color-grey;
}
.rounded-s {
  border-radius: 8px;
}

.fw-700 {
  font-weight: 700 !important;
}

.text-sm {
  font-size: 0.8rem;
}

.cursor-pointer {
  cursor: pointer !important;
}

.font-bold {
  font-weight: 500;
}

.header {
  font-size: 36px;
  font-weight: 700;
  color: $color-grey;

  @media (min-width: 991px) {
    font-size: 50px;
  }
}

.color-light {
  color: #fff;
  opacity: 0.7;
}

.ls-m {
  letter-spacing: 1px;
}

.text-link:hover {
  color: initial;
}

.profile-lg {
  width: 90px;
  height: 90px;
  background-size: cover;
  background-position: center;
  background-color: #f2f5f7;
}
.overflow-y {
  overflow-y: auto;
}
.rounded-m {
  border-radius: 20px;
}

.badge-light-purple {
  color: $color-purple;
  background-color: #f2f5f7;
  border-radius: 6px;
  font-size: 13px;
  margin-right: 8px;
}
.w-xl-85 {
  width: 100% !important;
  @media (min-width: 1800px) {
    max-width: 1200px;
  }
}

.mw-570 {
  max-width: 570px !important;
}
.mw-1167 {
  max-width: 1167px;
}

.font-s {
  font-size: 14px !important;
}
.bg-light-blue {
  background-color: $color-light-purple;
}
.badge-light {
  color: $color-purple;
  background: $color-light;
  font-weight: 600;
  font-size: 13px;
  border-radius: 6px;
  padding: 2px 5px;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  @media (min-width: 767px) {
    max-width: 160px;
  }
}

.mw-170 {
  max-width: 100%;

  @media (min-width: 767px) {
    max-width: 170px;
  }
}
.h-44 {
  height: 44px;
}
.mw-354 {
  max-width: 354px;
  @media screen and (min-width: 768px) and (max-width: 991.98px) {
    max-width: 100%;
  }
}
.status-purple {
  display: inline-block;
  font-weight: 600;
  font-size: 15px;
  color: $color-purple;
  background-color: $color-light-purple;
  padding: 2px 12px;
  border-radius: 6px;
  position: relative;
  border-radius: 0.25rem;
  &:hover {
    color: $color-purple;
  }
}
.arrow-right {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxMCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNSAwLjVMOSA4TDEuNSAxNS41IiBzdHJva2U9IiM0MzU5NjkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K)
    no-repeat;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.arrow-left {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxMCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNSAwLjVMMSA4TDguNSAxNS41IiBzdHJva2U9IiM0MzU5NjkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K)
    no-repeat;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.mx-n1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.mx-n2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

@media (min-width: 992px) {
  .mx-md-n1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .mx-md-n2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}

.wizard-holder label {
  background-color: transparent;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.opaque-7 {
  opacity: 0.7;
}

.h-90 {
  height: 90px;
}

.h-400 {
  height: 400px;
}

.mh-40 {
  min-height: 40px;
}

.radius-m {
  border-radius: 8px;
}

.radius-14 {
  border-radius: 14px;
}

.radius-l {
  border-radius: 20px;
}

.border-color {
  border-color: $color-border;
}

.float-right {
  float: right;
}

.h-120 {
  height: 120px;
}

.color-red {
  color: $color-red;
}

.athlete-page-bg {
  background: white !important;
}
div.white-pre-wrap {
  white-space: pre-wrap !important;
}
.word-break {
  word-break: break-word;
}
.br-5 {
  border-radius: 5px !important;
}

a.hover-inherit:hover {
  color: inherit;
}

.titlecase {
  text-transform: capitalize;
}

.color-purple {
  color: $color-purple;
}
.font-xs {
  font-size: 12px;
}
.font-xxs {
  font-size: 10px;
}
.w-120 {
  width: 120px;
}

.highlighted {
  border: 2px solid #8f64fc !important;
}

.compensation_input {
  border: none;
  width: 65px;
  margin-left: 10px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  @media screen and (max-width: 992px) {
    width: 80px;
  }
}
.tootlipCopy {
  width: 120px;
  cursor: pointer;
  .ant-tooltip-inner {
    width: 100px;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    text-align: center;
  }
}

// #loader-indicator {
//   position: fixed;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 99999;
//   background-color: #0000001f;
//   display: none;
// }
.box-shadow-tab {
  box-shadow: 0px 27px 90px rgba(0, 0, 0, 0.07) !important;
}
.scroll-custom-tag {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  display: grid;
}
