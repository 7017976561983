.pricing-section {
  color: white;
  padding: 1em;
  @media screen and (max-width: 992px) {
    padding-left: 0;
    padding-right: 0;
  }

  .pricing-texts {
    @media screen and (min-width: 992px) {
      width: 810px;
    }
  }

  .borderRow {
    border-bottom: 1px solid rgba(211, 220, 225, 1);
    border-width: medium;
  }

  .sammary-pad {
    @media screen and (max-width: 992px) {
      padding: 0;
    }
  }

  .start {
    text-align: start;
    display: flex;
    justify-content: left;
    align-items: center;
    //background-color: green;
  }

  .badgedetails {
    height: 30px;
    width: 124px;
    border-radius: 8px;
    background: rgba(193, 252, 174, 0.59);
    color: black !important;
    font-family: 'Manrope';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
  }

  .inline {
    display: inline;
  }

  .center {
    justify-content: space-around;
    padding: 0.6em;
    text-align: center;
  }

  .headerText {
    margin-bottom: 60px;
  }

  .title {
    font-family: 'Manrope';
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: 70px;
    letter-spacing: 0px;
    text-align: center;
    padding-bottom: 0;
  }

  .subtext1 {
    color: #ffffff;
    font-family: 'Manrope';
    font-size: 22px;
    font-style: normal;
    font-weight: normal;
    line-height: 34px;
    text-align: center;
    opacity: 0.75;
    @media screen and (max-width: 992px) {
      font-size: 19px;
      line-height: 29px;
    }
    @media screen and (min-width: 992px) {
      width: 800px;
    }
  }

  .subtext2 {
    color: #ffffff;
    font-family: 'Manrope';
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    text-align: center;
    padding: 1em 0em 1em 0em;
    opacity: 0.75;
    @media screen and (min-width: 992px) {
      width: 850px;
      margin-left: -25px;
    }
  }

  .subtext3 {
    font-family: 'Manrope';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    opacity: 0.75;
    @media screen and (min-width: 992px) {
      width: 850px;
      margin-left: -25px;
    }
  }

  .content {
    min-height: 1200px;
    width: 100%;
    background: white;
    color: black;
    padding: 5em 0em 4em 0em;
    justify-content: center;

    .titles {
      font-family: 'Manrope';
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px;
      letter-spacing: 0px;
      text-align: center;
      cursor: pointer;
      @media screen and (max-width: 992px) {
        font-family: Manrope;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: center;
      }
    }

    .bgCheck {
      justify-content: start;
      background-repeat: no-repeat;
    }

    .liststext {
      text-align: start;
      justify-content: start;
      font-family: 'Manrope';
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 23px;
      letter-spacing: 0px;
      text-align: left;
      color: #435969;
      padding: 0em 0em 1.1em 0em;
    }

    .bullet {
      width: 25px;
      @media screen and (max-width: 992px) {
        width: 35px;
      }
    }

    .a-styled-button {
      background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
      border-radius: 8px;
      margin-top: 20px;
      box-shadow: 0px 10px 20px rgba(31, 39, 167, 0.2);
      @media screen and (max-width: 992px) {
        max-width: 90%;
      }
    }

    .styledBtn {
      background: linear-gradient(88.91deg, #895cf8 -1.71%, #1f27a7 107.9%);
      border-radius: 8px;
      height: 76px;
      width: -webkit-fill-available;
      width: -moz-available;
      font-family: 'Manrope';
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      text-transform: uppercase;
      line-height: 23px;
      letter-spacing: 0.3px;
      text-align: center;
      color: white;
      border: none;
    }

    .strikeBotton {
      border-bottom: 1px solid rgba(164, 211, 211, 1);
      // padding-top: 24px;
      padding-top: 0;
      padding-bottom: 24px;
      max-width: 100%;
      margin-left: calc(0.5 * var(--bs-gutter-x));
      margin-right: calc(0.5 * var(--bs-gutter-x));
      @media screen and (max-width: 992px) {
        margin-left: 20px;
        border-bottom: none;
        position: relative;
      }
      .mob-border-bottom {
        @media screen and (max-width: 992px) {
          position: absolute;
          height: 1px;
          width: 95%;
          background-color: #a4d3d3;
          bottom: 0;
          right: 5%;
        }
      }
    }

    .brand-title {
      padding-top: 50px;
      @media screen and (max-width: 992px) {
        padding-right: 15px;
      }
    }

    .athlete-title {
      @media screen and (min-width: 992px) {
        margin-bottom: 10px !important;
        padding-bottom: 15px;
      }
    }

    .brand-deals {
      @media screen and (min-width: 992px) {
        padding-bottom: 15px;
      }
    }

    .pricingSub {
      margin-left: calc(0.5 * var(--bs-gutter-x));
      margin-right: calc(0.5 * var(--bs-gutter-x));
      @media screen and (max-width: 992px) {
        margin-left: 20px;
      }
    }

    .brand-pricing-sub {
      padding-bottom: 50px;
      @media screen and (max-width: 992px) {
        padding-right: 15px;
      }
    }

    .pricingSubBrand {
      padding-bottom: 5%;
    }

    .cancelText {
      color: #435969;
      font-weight: 500;
    }

    .cardSubtitle {
      width: 100%;
      margin-left: 10px;
      font-size: 17px;
      font-weight: 500;
      color: #435969;
      @media screen and (max-width: 992px) {
        margin-left: 0;
        font-size: 15px;
      }
    }

    .mobile-break {
      @media screen and (min-width: 995px) {
        display: none;
      }
    }

    .summary {
      background-color: red;
    }

    .pricingCard {
      width: 100%;
      //background-color: red;
    }

    .cardMainText {
      font-family: 'Manrope';
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 38px;
      letter-spacing: 0px;
      text-align: center;

      @media screen and (max-width: 992px) {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 22px;
        line-height: 30px;
        text-align: center;
        margin-right: 15px;
      }
    }

    .cardMainTextContent {
      margin-left: 5px;
      @media screen and (max-width: 992px) {
        position: relative;
        margin-right: 20px;
      }
    }

    .pricetext .monthtext {
      @media screen and (max-width: 992px) {
        position: absolute;
      }
    }

    .pricetext {
      @media screen and (max-width: 992px) {
        top: 0;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 41px;
        text-align: right;
        color: #042237;
      }
    }
    .monthtext {
      margin-left: 20px;
      margin-top: 10px;
      font-size: 16px;
      line-height: 22px;
      @media screen and (max-width: 992px) {
        margin: 0;
        bottom: 0;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        color: #042237;
      }
    }

    .cardSubTexts {
      font-family: 'Manrope';
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0px;
      text-align: left;
      @media screen and (max-width: 992px) {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
      }
    }
    img {
      margin-left: 1em;
    }

    .bggrey {
      background: rgba(242, 245, 247, 1);
      padding: 2em;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    .bggreyy {
      background: rgba(242, 245, 247, 1);
      border-top-right-radius: 50px;
      border-top-left-radius: 50px;
    }
  }

  input {
    appearance: none;
    padding: 8px 0 8px 33px;
    border-radius: 16px;
    height: 64px;
    background: rgba(242, 245, 247, 1);
    background-repeat: no-repeat;
    transition: 0.3s ease-in-out;
    @media (min-width: 768px) {
      width: 100%;
    }
  }
  input::before {
    content: 'MONTHLY';
    font-family: 'Manrope';
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0;
  }
  :checked {
    text-align: right;
  }
  :checked::before {
    content: 'YEARLY';
  }

  .prbutton {
    border: none;
    background: linear-gradient(89.92deg, #895cf8 -2.48%, #1f27a7 142.31%);
    margin-top: -24px;
    width: unset;
    height: unset;
    font-weight: 700;
    letter-spacing: 0;
    border-radius: 8px;
    font-size: 16px;
  }

  .selectedTab {
    // border-bottom: 1px solid #d3dce1;
    font-family: 'Manrope';
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    color: #042237;
    @media screen and (max-width: 992px) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  .selectedTabC {
    width: 60%;
    height: 5px;
    background-color: #7741fe;
    margin: auto;
    margin-top: 15px;
    margin-bottom: -3px;
    @media screen and (max-width: 992px) {
      width: 70%;
    }
  }

  .pad0 {
    padding: 0px;
  }

  .line {
    border: 1px solid #d3dce1;
  }

  .unselectedTitle {
    // border-bottom: 3px solid rgba(211, 220, 225, 1);
    // padding-bottom: 10px;
    color: #435969;

    opacity: 0.7;
    font-family: 'Manrope';
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    @media screen and (max-width: 992px) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  .getStarted {
    width: 100%;
    height: 76px;
    background-image: linear-gradient(210deg, #1f27a7, #895cf8);
  }

  .bg-pricing-list {
    background-color: #f2f5f7;
    margin-top: -50px;
    padding: 70px 50px 30px 50px;
    border-radius: 0px 0px 20px 20px;
    z-index: -1;
  }

  .div-center {
    margin-left: auto;
    margin-right: auto;
  }

  .q-circle {
    background-color: #b0fce2;
    padding: 0px 6px;
    border-radius: 10px;
  }
}
.discountTab {
  width: 124px;
  height: 28px;
  background-color: #c1fcae96;
  font-size: 15px;
  font-weight: 700;
  border-radius: 8px;
  position: absolute;
  left: 100%;
  top: 30%;
  margin-left: 5%;
  justify-content: center;
  align-items: center;
  border: 3px solid transparent;
  @media screen and (max-width: 767px) {
    left: 50%;
    top: 65%;
  }
}
.cardTitle {
  font-family: 'Manrope';
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 33px;
  letter-spacing: 0px;
  text-align: left;
  width: 100%;

  @media screen and (max-width: 992px) {
    font-family: 'Manrope';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0px;
    text-align: left;
  }
}
.discountTabContent {
  width: 100%;
  height: 100%;
  text-align: center;
  color: #042237;
  @media screen and (max-width: 767px) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 8px solid transparent;
      border-bottom-color: #c1fcae96;
      border-top: 0;
      margin-left: -10px;
      margin-top: -10px;
    }
  }
}

.ant-tooltip-inner {
  background-color: #dee1e2;
  color: #435969;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  font-size: 15px;
  font-weight: 500;

  @media screen and (max-width: 992px) {
    padding: 10px;
    border-radius: 5px;
    width: 200px;
    font-size: 12px;
  }
}

.cc {
  background-color: #f2f5f7;
  max-width: 100%;
  padding-left: calc(-0.5 * var(--bs-gutter-x));
  padding-right: calc(-0.5 * var(--bs-gutter-x));
  overflow: visible;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;

  @media screen and (max-width: 992px) {
    margin-left: 0;
    margin-right: 0;
    justify-content: center;
    align-items: center;
    max-width: 90%;
  }
}
.sammary {
  background-color: #f2f5f7;
  max-width: 100%;
  padding-left: calc(-0.5 * var(--bs-gutter-x));
  padding-right: calc(-0.5 * var(--bs-gutter-x));
  overflow: visible;
  border-top-right-radius: 25%;
  border-top-left-radius: 25%;
  margin-top: 35px;
  color: #042237;

  @media screen and (max-width: 992px) {
    margin-left: 0;
    margin-right: 0;
    justify-content: center;
    align-items: center;
    max-width: 90%;
  }

  .sammary-bg {
    background: url('../../../../public/images/pricingbase.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: content-box;
    border-radius: 30px;
    margin-top: -10%;
    @media screen and (max-width: 992px) {
      background: url('../../../../public/images/pricingbase-mob.png');
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: cover;
      background-clip: content-box;
    }
  }

  .cardSpacing {
    text-align: right;
  }

  .mob-price {
    @media screen and (max-width: 992px) {
      height: 30px;
    }
  }

  .credit-card-text {
    padding-bottom: 35px;
    color: #435969;
    font-size: 17px;
    font-weight: 500;
    @media screen and (max-width: 992px) {
      font-size: 15px;
      margin-top: 10px;
    }
  }

  .price-img {
    cursor: pointer;

    @media screen and (min-width: 992px) {
      // padding-right: 10px;
    }
  }
}

.price-img-shadow {
  font-size: 10px;
  cursor: pointer;
  box-shadow: 0 0 1px 1px #435969;
  border-radius: 50%;
  height: 15px;
  display: flex;
  width: 15px;
  justify-content: center;
  align-items: center;
}

.card-grid.card-var-2 .price-img {
  cursor: pointer;
  margin-left: 5px;
  position: relative;
  img {
    box-shadow: 0 0 1px 1px #26b385;
    border-radius: 50%;
  }
  @media screen and (min-width: 992px) {
    // padding-right: 10px;
  }
}
.benefits {
  padding: 6%;
  padding-bottom: 18px;
  @media screen and (min-width: 992px) {
    padding-top: 5%;
  }
}

.toggle-switch {
  float: left;
}

.contentC {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  max-width: 288px;
  transform: translateY(5%);
}

p {
  // don not add this css
  // font-size: 90%;
}

label,
.toggle {
  height: 64px;
  border-radius: 100px;
  margin: 5px;
}
label {
  width: 100%;
  background-color: #f2f5f7;
  border-radius: 100px;
  position: relative;
  margin: 1.8rem 0 4rem 0;
  cursor: pointer;
}
.toggle {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 6px 18px rgba(33, 29, 99, 0.08);
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  height: 54px;
  width: 131px;

  @media screen and (max-width: 992px) {
    width: 48%;
  }
  @media screen and (min-width: 1200px) {
    width: 139px;
  }
}

.profile-main.pricing-comp {
  .toggle {
    @media screen and (min-width: 1200px) {
      width: 130px;
    }
  }
}
.names {
  width: 100%;
  margin-left: 0%;
  margin-top: 3%;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.dark {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #435969;
  opacity: 0.7;
}

.light {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #435969;
  opacity: 0.5;
}

.current {
  color: #7741fe;
  opacity: 1;
}

[type='checkbox'] {
  display: none;
}
/* Toggle */
[type='checkbox']:checked + .app .toggle {
  transform: translateX(100%);
  background-color: #ffffff;
}
[type='checkbox']:checked + .app .dark {
  color: #7741fe;
}
[type='checkbox']:checked + .app .light {
  color: #435969;
}
.padding-main {
  padding: 0;
}
.scroll-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: #4f3fcc;
  cursor: pointer;
  z-index: 999;
  .up-arrow {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    text-align: center;
    height: 15px;
    width: 15px;
    margin-top: 5px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}
