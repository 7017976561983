.margin-class {
  margin-left: 18px;
  margin-right: 30px;
}

.tagged-campaign-text {
  color: #7741FE;
  font-weight: 800;
  text-decoration: underline;
}
