@import './variable.scss';

.view-apply-button {
  background: #042237;
  border-radius: 8px;
  color: white;
  padding: 10px 30px;
  border: none;
  font-size: 14px;
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    font-size: 15px;
    padding: 5px 20px;
  }
  @media screen and (max-width: 992px) {
    width: -webkit-fill-available;
    width: -moz-available;
    font-size: 15px;
  }
}

.campaign-divider {
  background: #d3dce1;
  height: 1px;
}

.campaign-content {
  @media screen and (min-width: 1350px) {
    width: 100%;
  }
  @media screen and (max-width: 992px) {
    margin: 0 !important;
    width: unset;
  }
}

.cam-deliverable {
  @media screen and (max-width: 992px) {
    flex-direction: column;
    margin-right: 20px;
    gap: 8px;
  }
}

.campaign-title {
  font-size: 24px;
  @media screen and (max-width: 992px) {
    font-size: 20px;
  }
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    font-size: 18px;
    line-height: normal;
  }
}

.cam-ellipse-img {
  position: absolute;
  z-index: -1;
  right: 0%;
  top: 20%;
}

.cam-unregistered-amt {
  font-weight: 800;
  font-size: 26px;
  line-height: 32px;
  text-align: right;
  color: $color-grey;
  @media screen and (max-width: 992px) {
    font-weight: 700;
    font-size: 24px;
    text-align: left;
    margin-top: 14px;
  }
}

.deliverable-item {
  @media screen and (min-width: 992px) {
    width: 33%;
  }
  margin-bottom: 6px;
}
