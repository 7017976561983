.white-header-style {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2000;
  color: white;
  background: white;
  overflow: hidden;
  .container {
    @media (max-width: 992px) {
      max-width: 100%;
    }
  }
  &.header-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
  }
  &.header-light {
    margin-top: 0;
    background-color: transparent;
  }
  .collapse:not(.show) {
    display: block;
    height: 0;
  }
  .collapse.show {
    height: auto;
    transition: height 0.35s ease;
  }
}

.white-nav-texts {
  font-family: 'Manrope';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: #042237;
  height: 70px;
  @media screen and (min-width: 991px) and (max-width: 1200px) {
    font-size: 14px;
  }
}

.log-in-text {
  color: #a784ff !important;
  padding: 1.5rem !important;
}

.white-nav-link {
  padding: 1.5rem !important;
  color: #042237 !important;

  &.active {
    .highlight-link {
      height: 4px;
      background: linear-gradient(91.42deg, #8df49b -43.09%, #589cf9 291.26%);
      border-radius: 2px;
      margin-top: 2px;

      @media screen and (max-width: 992px) {
        visibility: hidden;
      }
    }
  }
}

.signbtn {
  height: 45px;
  width: 155px;
  border-radius: 4px;
  background: linear-gradient(89.92deg, #895cf8 -2.48%, #1f27a7 142.31%);
  margin-left: 24px;
  font-family: 'Manrope';
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
  border: none;
  a,
  a:hover {
    color: white;
  }
}

.navbar-toggler {
  border: none !important;
  &:focus {
    box-shadow: none !important;
  }
}
