.deal-header {
  font-size: 55px;
  @media screen and (max-width: 991.98px) {
    font-size: 36px;
  }
}

.deal-offer-row {
  box-shadow: inset 4px 0px 0px #8f64fc;
}

.deal-table-img {
  margin-right: 6px;
  display: inline-table !important;
}

.msg-divider {
  height: 1px;
  background: #d3dce1;
  width: 100%;
}

.msg-box-mob {
  padding: 18px 20px;
  border: 1px solid #d3dce1;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
}

.msg-icon {
  position: absolute;
  top: 30%;
  right: 5%;
}
