.cam-del-type {
  .ant-select-selector {
    .ant-select-selection-item {
      white-space: break-spaces;
    }
  }
}

.card-options {
  .ant-radio-group {
    margin-top: 20px;
  }

  .ant-radio-wrapper {
    justify-content: left;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #042237;
  }
}
