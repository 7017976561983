@import './bootstrap_min.scss';
@import './variable.scss';
@import './input.scss';
@import './alert.scss';
@import './utility.scss';
@import './sidebar.scss';
@import './layout.scss';
@import './card.scss';
@import './tabs.scss';
@import './modal.scss';
@import './stepper.scss';
@import './theme.scss';
@import './account.scss';
@import './table.scss';
@import './messsages.scss';

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: Manrope;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  width: 100%;
  // background: #092031;
  color: white;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-grey;
  }

  .theme-dark {
    background: #092031;
    position: relative;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: inherit;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace, Manrope;
}

a {
  text-decoration: none !important;
}

.loader {
  position: absolute;
  left: 45%;
  top: 50%;
  margin-left: -36px;
  margin-top: -30px;
}

.card {
  margin-bottom: 1rem;

  .card-title {
    font-size: 1.5em;
    color: black;
  }

  .card-text {
    font-weight: 500;
    font-size: 1rem;
    color: #435969;
    line-height: 28px;

    @media screen and (max-width: 700px) {
      font-size: 13px;
      line-height: 20px;
    }
  }

  &:hover {
    background: rgb(228, 228, 228);
  }
}

.newly-joined-card {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  height: 100%;
  cursor: pointer;
  .card-body {
    display: flex;
    flex-direction: column;
    @media (max-width: 767.98px) {
      padding: 8px;
    }
  }
}
.newly-joined-section {
  .card-body {
    @media (max-width: 767.98px) {
      .athlete-name .card-text,
      .card-text {
        font-size: 12px !important;
      }
      .athlete-name.card-title {
        font-size: 13px;
      }

      .font-resize-m span {
        font-size: 10px !important;
      }
    }
  }
}
.main-content {
  margin-top: 90px;

  @media screen and (max-width: 992px) {
    margin-top: 60px;
  }
}

.row {
  @media screen and (max-width: 992px) {
    --bs-gutter-x: 0;
  }
}

.auth-holder {
  max-width: 500px;
}

.text-purple {
  color: $color-purple;
}

.bg-purple {
  background: $color-purple;
}

.marketing-bg {
  background: #092031;
}

.page-contain {
  max-width: 1660px;
  margin: 0 auto;
}

.mobile-profile-footer {
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.athlete-modal .form-group {
  ::-webkit-input-placeholder {
    color: $color-grey;
    font-weight: 500;
  }

  ::-moz-placeholder {
    color: $color-grey;
    font-weight: 500;
  }

  /* firefox 19+ */
  :-ms-input-placeholder {
    color: $color-grey;
    font-weight: 500;
  }

  /* ie */
  input:-moz-placeholder {
    color: $color-grey;
    font-weight: 500;
  }
  &.input-custom-var2 {
    height: auto;
  }
}

.object-fit-contain {
  object-fit: contain;
}

.div-skeleton {
  position: relative;
  overflow: hidden;
  background: #fff;
  width: 100%;
  height: 16px;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;

  &:after {
    position: absolute;
    inset: 0 -150%;
    background: linear-gradient(
      90deg,
      rgba(190, 190, 190, 0.2) 25%,
      rgba(129, 129, 129, 0.24) 37%,
      rgba(190, 190, 190, 0.2) 63%
    );
    -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
    animation: ant-skeleton-loading 1.4s ease infinite;
    content: '';
  }
}

.profile-sm img {
  border-radius: 7px;
}
.z-index-2 {
  z-index: 2;
}
.w-300 {
  width: 300px;
}
.letter-spacing-n {
  letter-spacing: -4px;
}
.cross-sign {
  position: relative;
  top: 11px;
  font-size: 28px;
  width: 1px;
  height: 20px;
  text-align: center;
  margin: 0 9px;

  &:before,
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    content: ' ';
    height: 10px;
    width: 2px;
    background-color: #333;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
.bg-color-border {
  background-color: #dee2e6;
}
.circle-dot {
  background-color: $color-grey;
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.bg-color-grey {
  background-color: $color-grey;
}
.deals-tab .ant-tabs.ant-tabs-top.ant-tabs-large {
  overflow: visible;
}
