$color-blue: #4f3fcc;
$color-purple: #7741fe;
$color-body: #435969;
$color-light: #f2f5f7;
$color-sidebar: #f0f4f6;
$color-border: #d3dce1;
$color-grey: #042237;
$color-green: #31bf83;
$color-light-purple: #e2d9fc;
$color-red: #ba4040;
$radius-m: 8px;
$radius-l: 20px;
$bg-light: #f0f3f5;
$color-green: #31bf83;
$color-border: #d3dce1;
$sendbird-purple: #742ddd;
$color-light-var-1: #d3dce1;
