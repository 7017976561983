.ant-notification {
  z-index: 999999;
}
.alert.alert-light {
  background-color: #ebe3ff;
  font-family: Manrope;
  border: none;
  font-family: Manrope;

  .ant-notification-notice-message {
    color: $color-purple;
    font-weight: 600;
    margin: 0;
  }
}
