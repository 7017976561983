.social-media-meter {
  border: 2px solid white;
  height: 20px;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  max-width: 640px;
  display: flex;
  // grid-template-columns: 10fr 10fr 10fr 10fr 10fr;
  &.media-icon-set {
    height: auto;
  }
  .media-icon {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 16px;
    }
    @media (min-width: 767px) {
      margin: 6px 0;
    }
  }
}

#fruit-meter div {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#facebook {
  background: #895cf8;
  color: #895cf8;
}

#instagram {
  background: #00e3de;
  color: #00e3de;
}

#twitter {
  background: #b9fcaa;
  color: #b9fcaa;
}

#music {
  background: #1e2ba2;
  color: #1e2ba2;
}

#youtube {
  background: #80f1c6;
  color: #80f1c6;
}
