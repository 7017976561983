.purchaser-margin {
  margin-top: 65px;
}

.discover-athletes-margin {
  margin-top: 90px;
  overflow: hidden;
  @media screen and (max-width: 992px) {
    margin-top: 65px;
  }
}

.purchaser-home-margin {
  margin: 60px 0 0;
  .container-purchaser-profile {
    @media screen and (min-width: 768px) and (max-width: 992px) {
      max-width: 100%;
    }
  }
  @media screen and (min-width: 992px) {
    margin: 80px 0 0;
  }

  @media screen and (min-width: 1250px) {
    margin: 80px 8% 0;
  }

  @media screen and (min-width: 1380px) {
    margin: 80px 10% 0;
  }
}

.cover {
  position: relative;
  background: url('../../../assets/images/coverBg.png') left top no-repeat;
  background-size: cover;
  min-height: 332px;
  width: 100%;
  // max-width: 1440px;
  margin: 0 auto;

  @media screen and (max-width: 992px) {
    min-height: 262px;
    height: 100%;
  }
}

.infoImg {
  position: relative;
  background: url('../../../assets/images/infoImg.png') left top no-repeat;
  background-size: cover;
  min-height: 63px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  @media screen and (max-width: 992px) {
    min-height: 66px;
    height: 100%;
  }
}

.coverImg {
  position: absolute;
  background: url('../../../assets/images/coverImg.png') right top no-repeat;
  height: 100%;
  width: 100%;
  // margin-right: 3%;

  @media screen and (max-width: 992px) {
    visibility: hidden;
    min-height: 0%;
  }
}

.coverText {
  position: absolute;
  top: 20%;
  //   margin-left: 25%;
  width: 53%;
  color: #042237;
  font-family: 'Manrope';
  font-style: normal;

  @media screen and (max-width: 600px) {
    // margin-left: 5%;
    // margin-right: 5%;
    width: 90%;
  }
}

.coverTitle {
  font-weight: bold;
  font-size: 55px;
  line-height: 64px;
  margin-bottom: 3%;
  color: #042237;

  @media screen and (max-width: 992px) {
    font-size: 36px;
    line-height: 46px;
  }
}

.coverSub {
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #435969;

  @media screen and (max-width: 992px) {
    font-size: 17px;
    line-height: 28px;
  }
}

.content {
  background-color: #ffffff;
  padding-top: 3%;
  padding-bottom: 3%;
}

.sportBarContainer {
  background-color: #f2f5f7;
  display: flex;
  width: 100%;
  border-radius: 16px;
  height: 5rem;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
  margin-bottom: 2.5em;
  overflow-x: auto;
  white-space: nowrap;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    // display: none;
    margin-bottom: 20px;
  }
}

.scroll-custom {
  &.newly-joined-section {
    .athlete-card .card {
      height: 100%;
    }
    @media screen and (min-width: 767.98px) and (max-width: 992px) {
      .col {
        width: 37.33%;
        padding: 5px;
      }
    }
    @media screen and (min-width: 992px) and (max-width: 1200px) {
      .col-lg-4 {
        width: 28%;
      }
    }
    @media (min-width: 1200px) {
      .col {
        width: 22%;
      }
    }
    @media (min-width: 1800px) {
      .col-xl-3.col {
        width: 17%;
      }
    }
    @media (min-width: 2600px) {
      .col-xl-3.col {
        width: 19%;
      }
    }
    .col-6 {
      @media (max-width: 767px) {
        width: 40%;

        .card {
          height: 100%;
        }
      }
    }
    .athlete-card .card .image_holder_card,
    .placeholder-athlete-icon {
      height: 100px;
      @media (min-width: 568px) {
        height: 150px;
      }
    }

    // @media (max-width: 767px) {
    //   .athlete-name.card-title {
    //     font-size: 14px;
    //   }
    //   .athlete-card .card .card-text {
    //     font-size: 10px;
    //     line-height: normal;
    //   }
    // }
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 12px;
    height: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #4f3fcc;
  }
}

.sportText {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  line-height: 25px;
  color: #042237;
  border-radius: 16px;
  margin: 0 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: clip;
  background-color: transparent;
  padding: 3px;
  &:hover div {
    background-color: transparent;
  }

  &.active {
    background-color: #fff;
    background-color: #fff;
    background: linear-gradient(248deg, #589cf9, #8df49b);
    -webkit-transition: 1s ease;
    transition: 1s ease;
    border-radius: 16px !important;
    height: auto;
    font-weight: 600;
    opacity: 1;
    position: relative;

    &:after {
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(
        180deg,
        rgba(141, 244, 155, 0.1) 0%,
        rgba(88, 156, 249, 0.1) 100%
      );
      width: 100%;
    }
    & > div {
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background-color: #fff;
      height: 100%;
      border-radius: 13px;
      padding: 10px 15px;
    }
  }
}

.products {
  margin-top: 1em;
  color: black;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  //css for bubble
  background: white;

  // @media screen and (min-width: 768px) {
  //   width: 68%;
  // }

  // @media screen and (min-width: 1200px) {
  //   width: 85%;
  // }

  .productList {
    position: relative;
    background: inherit;
    background-size: auto, auto;
    /* css fro bubble */

    position: relative;
    z-index: 1;
    // min-height: 769px;
    // margin-left: 300px;
    @media screen and (max-width: 692px) {
      background: none;
      --bs-gutter-x: 0.5rem;
    }

    .card {
      height: 100%;
      max-width: 306px;
      border-radius: 20px;
      border: none;
      box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.05);

      @media screen and (max-width: 992px) {
        max-width: 100%;
      }

      @media screen and (max-width: 669px) {
        max-width: 100%;
        min-height: 294px;
      }
    }
  }
}

.center {
  justify-content: space-around;
  padding: 0.6em;
  padding-top: 3em;
  text-align: center;
}

.clickbtnblue {
  height: 60px;
  width: 200px;
  border-radius: 8px;
  background: #d3dce1;
  font-family: 'Manrope';
  font-size: 18px !important;
  font-style: normal;
  font-weight: 800 !important;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
  color: #435969;
  text-transform: uppercase;
  // margin: 3em 0em 3em 0em;
}

.view-more {
  font-family: 'Manrope';
  font-size: 18px !important;
  font-style: normal;
  font-weight: 800 !important;
  line-height: 23px;

  text-align: center;
  color: #435969;
  text-transform: uppercase;

  &.btn:focus {
    box-shadow: none;
  }
}

.filterSort {
  display: flex;
  overflow-x: auto;

  span.sortLabel {
    background-color: #f2f5f7;
    font-family: Manrope;
    color: #7741fe;
    font-weight: 600;
    font-size: 13px;
    line-height: 17.76px;
    padding: 7px;
    border-radius: 6px;
    border: none !important;
    margin-bottom: 5px;
  }
  .filter {
    display: flex;
    overflow-x: auto;
    @media (min-width: 992px) {
      justify-content: center;
    }
  }

  #dropdown {
    width: 15em;
    border: 1px solid #d3dce1;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 0.8rem;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #435969;

    opacity: 0.7;

    option {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #435969;
      opacity: 0.7;
      width: 15em;
      border: 1px solid #d3dce1;
      padding: 0.8rem;
      background-color: #ffffff !important;
    }
  }

  .athleteType {
    margin-right: 1em;
  }

  .listingType {
    margin-right: 1em;
  }
}

.sort {
  display: flex;
  //   justify-content: flex-end;
  margin-left: auto;
}

.filterIcon {
  display: flex;
  align-items: center;
  margin-right: 0.6em;

  @media screen and (max-width: 692px) {
    // display: none;
  }
}

.filterDrop {
  display: flex;
  border: 1px solid #d3dce1;
  box-sizing: border-box;
  border-radius: 16px;
}

.search {
  width: 100%;
  height: 3.5rem;
  background: rgba(242, 245, 247, 0.3);
  border: 1px solid #d3dce1;
  box-sizing: border-box;
  border-radius: 16px;
  padding-left: 1em;
  color: black;
}

.athletesHeading {
  max-width: 725px;
  margin-bottom: 50px;
}

.searchContainer {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  position: relative;

  athlete-sort {
    max-width: 290px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: #042237;
    font-weight: 700;
  }

  .sort-search {
    width: 50%;
    .ant-select.select-dropdown > .ant-select-selector,
    .ant-select.select-dropdown.ant-select-single {
      text-transform: capitalize;
      color: #042237;
      opacity: 1;
      font-weight: 500;
    }

    @media screen and (max-width: 767.98px) {
      display: none !important;
    }
  }

  .search {
    padding-left: 50px;
  }

  .sortIcon {
    padding: 1rem;
    display: none;

    @media screen and (max-width: 692px) {
      display: block;
    }
  }

  .search-container {
    width: 60%;
    max-width: 540px;

    @media screen and (max-width: 767.98px) {
      width: 100%;
    }
  }
}

.mobile-card {
  display: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: inline-flex;
  }

  img {
    padding-right: 6px;
  }

  @media screen and (max-width: 692px) {
    display: flex !important;
    padding-right: 20px;
  }
}

.mobile-athlete-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;

  div {
    display: inline-flex;
  }

  img {
    padding-right: 6px;
  }

  @media screen and (max-width: 692px) {
    display: flex !important;
    // padding-right: 20px;
    width: 100%;
  }
}

.desktop-card {
  display: block;

  @media screen and (max-width: 692px) {
    display: none;
  }
}

.ant-select.select-dropdown {
  height: 50px;
  margin-right: 10px;

  &.cam-filter-dropdown > .ant-select-selector {
    max-width: 400px;
    width: 350px !important;
  }

  & > .ant-select-selector {
    width: 15em !important;
    height: 100% !important;
    display: block;
    appearance: none;
    border: 1px solid #d3dce1;
    box-sizing: border-box;
    border-radius: 16px !important;
    padding: 0.5rem 0.8rem 0.5rem 0.8rem !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #435969;
    opacity: 0.7;
    max-width: 230px;
  }
}

.ant-select.select-dropdown.cam-del-type {
  .ant-select-selection-item {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
  }
  .ant-select-selector {
    // commented due to campain filter looks larger
    // height: 80px !important;
  }
}

.truncate {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-small {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table {
  margin-bottom: 0px;
}

.checkout-table {
  thead {
    th:first-child {
      -moz-border-radius: 8px 0 0 0;
      -webkit-border-radius: 8px 0 0 0;
      border-radius: 8px 0 0 0;
    }

    th:last-child {
      -moz-border-radius: 0 8px 0 0;
      -webkit-border-radius: 0 8px 0 0;
      border-radius: 0 8px 0 0;
    }

    tr {
      border-color: transparent;
    }
  }

  tr {
    border-top: solid 1px transparent;

    td,
    th {
      border-top: none;
      padding: 24px 30px;
    }

    td:first-child {
      -moz-border-radius: 0 0 0 8px;
      -webkit-border-radius: 0 0 0 8px;
      border-radius: 0 0 0 8px;
      border-left: solid 1px #d3dce1;
    }

    td:last-child {
      -moz-border-radius: 0 0 8px 0;
      -webkit-border-radius: 0 0 8px 0;
      border-radius: 0 0 8px 0;
      border-right: solid 1px #d3dce1;
    }
  }
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}

.purchase-order-table {
  // border: 1px solid #d9e1e7;
  border-bottom: none;
  border-radius: 8px;
  td,
  th {
    padding: 20px 30px;
    vertical-align: middle;
  }
  thead {
    font-size: 15px;

    th {
      font-weight: 500;
      border: none;
    }

    tr {
      border: none;
    }

    th:first-child {
      -moz-border-radius: 8px 0 0 0;
      -webkit-border-radius: 8px 0 0 0;
      border-radius: 8px 0 0 0;
    }

    th:last-child {
      -moz-border-radius: 0 8px 0 0;
      -webkit-border-radius: 0 8px 0 0;
      border-radius: 0 8px 0 0;
    }
    .video-background {
      flex: 0 0 68px;
    }
  }

  tbody {
  }
  tr {
    td:first-child {
      border-left: solid 1px #d3dce1;
      // -moz-border-radius: 0 0 0 8px;
      // -webkit-border-radius: 0 0 0 8px;
      // border-radius: 0 0 0 8px;
    }

    td:last-child {
      border-right: solid 1px #d3dce1;
      // -moz-border-radius: 0 0 8px 0;
      // -webkit-border-radius: 0 0 8px 0;
      // border-radius: 0 0 8px 0;
    }
  }
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}

.purchase-order-table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.purchase-order-table tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.purchase-order-table tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

.purchase-order-table tr td {
  border-bottom: 1px solid #d9e1e7;
}

.table-border {
  border: 1px solid #d3dce1;

  thead {
    border-radius: 20px;
  }
}

.th {
  background-color: #f2f5f7;
  color: #435969;
  vertical-align: middle !important;
}

.text-center {
  vertical-align: middle;
  text-align: center;
}

.no-border-top {
  border-top: none !important;
}

// .A-scroll-bar {
//   &::-webkit-scrollbar {
//     display: block;
//     width: 1.5rem;
//   }

//   &::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 0.5rem #f9fafb;
//     border-radius: 0.5rem;
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: #868e96;
//     border: 0.5rem solid transparent;
//     border-radius: 20px;
//     width: 0.5rem;
//     background-clip: content-box;
//   }
// }

.table-card {
  border-radius: 20px;

  .ant-card-body {
    padding: 0px;
  }
}

.table-with-infinite-scroll {
  table {
    border-radius: 20px;
  }

  thead {
    background-color: #f2f5f7;
    border-radius: 20px;
  }

  tbody {
    border-top: none !important;
  }

  thead th {
    vertical-align: middle;
    height: 60px;
    color: #435969;
    font-weight: 500;
    font-size: 15px;
    line-height: 20.5px;
    padding: 1.2rem;
  }

  td {
    padding: 1.2rem;
    vertical-align: middle;
    height: 66px;
    color: #042237;
    font-weight: 500;
    font-size: 15px;
    line-height: 20.5px;
  }

  tr:hover {
    // border-left: 5px solid #00f;
    background-color: #f2f5f4;
    width: 100%;
  }

  thead tr:hover {
    text-decoration: none !important;
    border-left: none !important;
  }
}

.order-id {
  font-weight: 700 !important;
}

.purchaser {
  display: inline-table !important;
  margin-right: 12px;
}

.status-progress {
  display: inline-block;
  font-weight: 600;
  font-size: 15px;
  color: #e46a25;
  background-color: rgba(228, 106, 37, 0.1);
  padding: 2px 12px;
  border-radius: 6px;
  position: relative;
  border: 1px solid #0000;
  border-radius: 0.25rem;

  @media screen and (min-width: 992px) {
    max-width: 120px;
  }
}

.offer {
  display: inline-block;
  font-weight: 600;
  color: #435969;
  background-color: rgba(67, 89, 105, 0.1);
  padding: 6px 12px;
  border-radius: 6px;
  position: relative;
  border: 1px solid #0000;
  border-radius: 0.5rem;

  @media screen and (min-width: 992px) {
    // max-width: 120px;
  }
}

.status-success {
  display: inline-block;
  font-weight: 600;
  color: #55af74;
  background-color: rgba(85, 175, 116, 0.1);
  padding: 6px 12px;
  border-radius: 6px;
  position: relative;
  border: 1px solid #0000;
  border-radius: 0.25rem;

  @media screen and (min-width: 992px) {
    max-width: 120px;
  }
}

.disputed {
  display: inline-block;
  font-weight: 600;
  color: #4f3fcc;
  background-color: rgba(31, 39, 167, 0.2);
  padding: 6px 12px;
  border-radius: 6px;
  position: relative;
  border-radius: 0.25rem;
}

.awaiting {
  background: #7741fe1a;
  border-radius: 6px;
  text-align: center;
  color: #7741fe;
  padding: 4px 6px;
}

.cancel {
  display: inline-block;
  font-weight: 600;
  color: #435969;
  background-color: rgba(67, 89, 105, 0.1);
  padding: 6px 12px;
  border-radius: 6px;
  position: relative;
  border: 1px solid #0000;
  border-radius: 0.25rem;

  @media screen and (min-width: 992px) {
    max-width: 120px;
  }
}

@for $i from 1 through 100 {
  .A-p-#{$i} {
    padding: ($i) * 1px !important;
  }

  .A-pt-#{$i} {
    padding-top: ($i) * 1px !important;
  }

  .A-pb-#{$i} {
    padding-bottom: ($i) * 1px;
  }

  .A-pr-#{$i} {
    padding-right: ($i) * 1px;
  }

  .A-pl-#{$i} {
    padding-left: ($i) * 1px !important;
  }

  .A-py-#{$i} {
    padding-top: ($i) * 1px;
    padding-bottom: ($i) * 1px;
  }

  .A-px-#{$i} {
    padding-left: ($i) * 1px !important;
    padding-right: ($i) * 1px !important;
  }

  .A-mt-#{$i} {
    margin-bottom: ($i) * 1px;
  }

  .A-mb-#{$i} {
    margin-top: ($i) * 1px;
  }

  .A-p-#{$i}-rem {
    padding: ($i) * 1rem;
  }

  .A-pt-#{$i}-rem {
    padding-top: ($i) * 1rem;
  }

  .A-pb-#{$i}-rem {
    padding-bottom: ($i) * 1rem;
  }

  .A-pr-#{$i}-rem {
    padding-right: ($i) * 1rem;
  }

  .A-pl-#{$i}-rem {
    padding-left: ($i) * 1rem;
  }

  .A-py-#{$i}-rem {
    padding-top: ($i) * 1rem;
    padding-bottom: ($i) * 1rem;
  }

  .A-px-#{$i}-rem {
    padding-left: ($i) * 1rem !important;
    padding-right: ($i) * 1rem !important;
  }

  .A-mb-#{$i}-px {
    margin-bottom: ($i) * 1px;
  }

  .A-mt-#{$i}-px {
    margin-top: ($i) * 1px;
  }

  .A-ml-#{$i}-px {
    margin-left: ($i) * 1px;
  }

  .A-mr-#{$i}-px {
    margin-right: ($i) * 1px;
  }

  .A-mb-#{$i}-rem {
    margin-bottom: ($i) * 1rem;
  }

  .A-mt-#{$i}-rem {
    margin-top: ($i) * 1rem;
  }

  .A-ml-#{$i}-rem {
    margin-left: ($i) * 1rem;
  }

  .A-mr-#{$i}-rem {
    margin-right: ($i) * 1rem;
  }

  .A-mb-minus-#{$i} {
    margin-bottom: ($i) * (-1px);
  }

  .A-flex-basis-#{$i} {
    flex-basis: ($i) * 1%;
  }

  .A-col-#{$i} {
    flex-basis: ($i) * 1%;
    position: relative;
  }

  .A-h-#{$i}-px {
    height: ($i) * 1px !important;
  }

  .A-h-#{$i} {
    height: ($i) * 1%;
  }

  .A-min-h-#{$i} {
    min-height: ($i) * 1%;
  }

  .A-max-h-#{$i} {
    max-height: ($i) * 1%;
  }

  .A-max-h-#{$i}-px {
    max-height: ($i) * 1px;
  }

  .A-vh-#{$i} {
    height: ($i) * 1vh;
  }

  .A-min-vh-#{$i} {
    min-height: ($i) * 1vh;
  }

  .A-max-vh-#{$i} {
    max-height: ($i) * 1vh;
  }

  .A-w-#{$i} {
    width: ($i) * 1%;
  }

  .A-w-#{$i}-px {
    width: ($i) * 1px !important;
  }

  .A-w-rem-#{$i} {
    width: ($i) * 1rem;
  }

  .A-min-w-#{$i} {
    min-width: ($i) * 1%;
  }

  .A-max-w-#{$i} {
    max-width: ($i) * 1%;
  }

  .A-max-w-rem-#{$i} {
    max-width: ($i) * 1rem;
  }

  .A-h-rem-#{$i} {
    height: ($i) * 1rem;
  }

  .A-vw-#{$i} {
    width: ($i) * 1vw;
  }

  .A-min-vw-#{$i} {
    min-width: ($i) * 1vw;
  }

  .A-max-vw-#{$i} {
    max-width: ($i) * 1vw;
  }

  .A-top-#{$i} {
    top: ($i) * 1%;
  }

  .A-top-px-#{$i} {
    top: ($i) * 1px !important;
  }

  .A-bottom-#{$i} {
    bottom: ($i) * 1%;
  }

  .A-left-#{$i} {
    left: ($i) * 1%;
  }

  .A-right-#{$i} {
    right: ($i) * 1%;
  }

  .A-left-rem-#{$i} {
    left: ($i) * 1rem;
  }

  .A-font-size-#{$i} {
    font-size: ($i) * 1px;
  }

  .A-zindex-#{$i} {
    z-index: ($i) * 1;
  }

  .A-col-#{$i} {
    position: relative;
    width: 100%;
    padding-right: 14px;
    padding-left: 14px;
    flex: 0 0 ($i) * 1%;
    max-width: ($i) * 1%;
  }
}

.athlete-name.card-title {
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
  margin: 0 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  // -webkit-box-orient: vertical;
  // line-height: 32px;
  color: #042237 !important;
  @media (min-width: 991px) {
    font-size: 18px;
  }
}

.about-profile {
  opacity: 0.6;
}

.brand-form > label {
  font-weight: 500;
  font-size: 18px !important;
  line-height: 25px;
  color: #435969 !important;
  opacity: 0.7;
}

.brand-form > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label {
  // color: #4f3fcc !important;
  opacity: 1 !important;
  font-weight: bold;
  font-size: 0.75rem !important;
}

.brand-form > .form-control:not(:placeholder-shown) ~ label {
  // color: #4f3fcc !important;
  opacity: 1;
  font-weight: bold;
  font-size: 0.75rem !important;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.large-screen {
  max-width: 1440px;
  margin: 0 auto;
}

.select-drop-down {
  .ant-select-dropdown {
    background: #042237;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-transform: capitalize;
  }

  .ant-select-item {
    border: 1px solid #f00;
    background: #042237 !important;
  }
}

.pay-modal-method {
  .ant-radio-wrapper {
    font-size: 16px;
    line-height: 25px;

    @media screen and (max-width: 992px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
